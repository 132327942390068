import { animate, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { EstateControlService } from 'src/app/services/estate-control.service';
import { TabControlService } from 'src/app/services/tab-control.service';
import { NotificationService } from 'src/app/services/notification.service';
import { decodeSessionData, hideEmailAddress, SessionData } from 'src/app/util/Helper';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-estate',
  templateUrl: './estate.component.html',
  styleUrls: ['./estate.component.scss'],
  animations: [
    trigger('slideIn', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)', opacity: 0 }),
        animate('300ms', style({ transform: 'translateX(0)', opacity: 1 })),
      ]),
    ]),
  ],
})
export class EstateComponent implements AfterViewInit {
  // Url Params
  session: string = ""; // used to resume session
  @ViewChild('scroller', { static: false }) scroller?: ElementRef;
  
  tabsAnimationState: boolean[] = [false, false, false, false];

  MileleLogoPath: string  = '../../../assets/Milele_logo_white.png';
  logoPath: string  = '../../../assets/logo_w.png';
  warnPath: string = '../../../assets/warn.png';

  disclaimer: string = 'Please note that we will be saving your information as you proceed along the steps';
  powerdByText: string = 'Powered by ICEA LION Group. All rights reserved.';

  showSteps:boolean = false;
  isPrivateTrust:boolean = true;

  iSwear = new FormControl(false);

  otpForm = this.formBuilder.group({
    otp: ['']
  });

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private tabControlService: TabControlService,
    private estateControlService: EstateControlService
  ) {
    this.estateControlService.planType = 0;
  }

  estateDataHolder: any = null;
  resumeActiveTextEstate: string = '';
  showContinuePopup: boolean = false;

  ngAfterViewInit() {
    // Trigger the animation for each tab after a slight delay
    this.animateTabs();
  }

  async ngOnInit(): Promise<void> {
    this.route.queryParams.subscribe(async (params) => {
      // console.log('Params---> ',params); // log route params
      try {
        if (params["session"] == undefined) {
          this.initLocal();
        } else {
          this.session = params["session"];
          this.estateControlService.session = this.session;
          this.initResume(this.session);
        }
      } catch (error) {
        console.error('Estate Planning Resume! ', error);
      }
    });
    
  }

  otpReady: boolean = false;
  otpValue: number = 0;
  otpLoading: boolean = false;
  otpEmail:string = 'that was used to set up the trust';

  get onlineStorageObject(): any {
    return this.estateControlService.onlineStorageObject;
  }
  get showResumePopup(): boolean {
    return this.estateControlService.showResumePopup;
  };

  get loading(): boolean {
    return this.notificationService.loading;
  };
  get loadingTab(): boolean {
    return this.notificationService.loadingTab;
  };
  get activeTab(): number {
    return this.estateControlService.activeTab;
  }
  get planType(): number {
    return this.estateControlService.planType;
  }

  get page1DoneEstate(): boolean {
    return this.estateControlService.page1Done;
  }
  get page2DoneEstate(): boolean {
    return this.estateControlService.page2Done;
  }
  get page3DoneEstate(): boolean {
    return this.estateControlService.page3Done;
  }
  get page4DoneEstate(): boolean {
    return this.estateControlService.page4Done;
  }
  get page5DoneEstate(): boolean {
    return this.estateControlService.page5Done;
  }
  get page6DoneEstate(): boolean {
    return this.estateControlService.page6Done;
  }

  // Type guard function to check if an object matches the SessionData type
  isSessionData(obj: any): obj is SessionData {
    return (
      typeof obj === 'object' &&
      obj !== null &&
      typeof obj.SessionId === 'string'
    );
  }

  async initLocal(onlineData: any = null): Promise<void> {
    const key = await this.estateControlService.getData(this.estateControlService.estateTypeKey);
    let data = await this.estateControlService.getData(key);
    if (onlineData) data = onlineData;

    if (data && data.timeStamp) {
      if (!environment.production) console.log('Data ', data);

      const timeDiff = new Date().getTime() - parseInt(data.timeStamp);
      this.estateDataHolder = data;
      
      if (data.activeTab === 1) {
        this.resumeActiveTextEstate = 'Settlor Information';
        if (data.planType === 1) this.resumeActiveTextEstate = 'Testator';
      }
      if (data.activeTab === 2) this.resumeActiveTextEstate = 'Payment';

      if (data.activeTab === 3) {
        this.resumeActiveTextEstate = 'Beneficiaries';
        if (data.planType === 1) this.resumeActiveTextEstate = 'Will Executor';
      }
      if (data.activeTab === 4) this.resumeActiveTextEstate = 'Assets';

      if (data.activeTab === 5) {
        this.resumeActiveTextEstate = 'Trustees';
        if (data.planType === 1) this.resumeActiveTextEstate = 'Beneficiaries';
      }
      if (data.activeTab === 6) {
        this.resumeActiveTextEstate = 'Enforcer';
        if (data.planType === 1) this.resumeActiveTextEstate = 'Witness';
      }

      if (data.activeTab === 7) this.resumeActiveTextEstate = 'Preview';
      

      if (this.estateDataHolder.TrustID) {
        if (!environment.production) console.log('timestamp ', this.estateDataHolder);

        if (timeDiff < 86400000 ) {
          this.showContinuePopup = true;
        }
        // else {
        //   await this.estateControlService.updateDataFromStorage();
        //   if (this.estateControlService.TrustID) {
        //     this.showEstateSteps();
        //   }
        // }
      }
    }
  }
  async initResume(session: string): Promise<void> {
    const sessionData = decodeSessionData(session);
    if (this.isSessionData(sessionData) && sessionData.SessionId && sessionData.SessionId !== '') {
      await this.estateControlService.checkProgress(session);
      if (this.onlineStorageObject) {
        if (this.onlineStorageObject.planType == 0) this.otpEmail = hideEmailAddress(this.onlineStorageObject.settlors[0].Email);
        else  this.otpEmail = hideEmailAddress(this.onlineStorageObject.testators[0].Email);
      }
    } else {
      this.initLocal();
    }
  }

  animateTabs() {
    for (let i = 0; i < this.tabsAnimationState.length; i++) {
      setTimeout(() => {
        this.tabsAnimationState[i] = true;
      }, 400 * i); // Adjust the delay as needed
    }
  }

  onOptionChange(value: boolean): void {
    this.isPrivateTrust = value;

    if (this.isPrivateTrust) {
      this.estateControlService.planType = 0;
    } else {
      this.estateControlService.planType = 1;
    }
    console.log('option : ', this.estateControlService.planType);
  }

  onOtpChange(otp: string) {
    if (otp.length === 6) {
      this.otpReady = true;
      this.otpValue = parseInt(otp);
    } else {
      this.otpReady = false;
    }
  }

  async verifyOTP(): Promise<void> {
    if (!this.otpLoading) {
      try {
        this.otpForm.disable();
        if (this.otpValue === this.onlineStorageObject.OTP) {
          // update data
          await this.estateControlService.updateDataFromStorage(this.onlineStorageObject);
          if (this.onlineStorageObject.TrustID) {
            this.showEstateSteps();
          }
          this.notificationService.viewToast('info', 'Session resumed');
        } else {
          this.notificationService.viewToast('error', 'Invalid OTP provided');
        }
      } catch (error) {
        console.error('Verify OTP! ', error);
        this.notificationService.viewToast('error', 'An error occurred');
      }
      this.closeOTPPopup();
    }
  }

  closeOTPPopup(): void {
    if (!this.otpLoading) {
      this.estateControlService.showResumePopup = false;
    }
  }

  navHome(): void {
    const nextPage = ``;
    this.router.navigate([nextPage]);
  }

  async resumeEstate(resume: boolean = true): Promise<void> {
    if (resume) {
      await this.estateControlService.updateDataFromStorage();
    
      if (this.estateControlService.TrustID) {
        this.showEstateSteps();
      }
    } else {
      this.estateControlService.resetLocalData();
    }
    this.showContinuePopup = false;
  }

  showEstateSteps(): void {
    this.showSteps = true;
  }
}
