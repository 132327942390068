import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { EstateControlService } from 'src/app/services/estate-control.service';
import { TabControlService } from 'src/app/services/tab-control.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-option-popup',
  templateUrl: './option-popup.component.html',
  styleUrls: ['./option-popup.component.scss']
})
export class OptionPopupComponent {
  @Output() close = new EventEmitter<void>();

  closePopup(): void {
    this.close.emit();
  }

  constructor (
    private estateControlService: EstateControlService,
    private notificationService: NotificationService,
    private tabControlService: TabControlService,
    private router: Router,
  ) {
    this.journeyType = 0;
  }


  journeyType: 0 | 1 = 0;
  setJourneyType(value: 0 | 1): void {
    this.journeyType = value;
  }

  async navBookAppointment(): Promise<void> {
    const nexPage = `/estate-planning`;
    this.router.navigate([nexPage]);
  }

  async navEstatePlanning(resume: boolean = false): Promise<void> {
    // this.showResumePopup = false;
    // if (resume) {
    //   await this.tabControlService.updateDataFromStorage();
    // } else {
    //   this.tabControlService.resetLocalEstateData();
    // }

    // const nexPage = `/estate-planning/set-up`;
    // this.router.navigate([nexPage]);
  }

  submitRequest(): void {
    this.closePopup();
    
    if (this.journeyType === 1) {
      const nexPage = `/estate-planning/set-up`;
      this.router.navigate([nexPage]);
    } else {
      const nexPage = `/estate-planning/appointment`;
      this.router.navigate([nexPage]);
    }

    
  }
}
