<div class="bottom-container row-bar">
    <div style="display: flex; flex: 1; align-items: center;" class="goldStroke">
        <button mat-stroked-button type="button" (click)="toPreviousTab()"
            class="btn-primary previous-button" *ngIf="showPrevious === 'true'">
                <span class="chevron-left"></span>
                {{'Previous'}}
        </button>

        <div class="float-end" >
            <div class="blue later" *ngIf="showContinue === 'true' && !validatingFile" (click)="saveFunction()" >
                <u>{{loadingUpdate ? 'Saving Progress' : 'Continue Later'}}</u>
                <span class="load-spinning" *ngIf="loadingUpdate">
                    <mat-progress-spinner
                        mode="indeterminate" diameter="20" strokeWidth="3"></mat-progress-spinner>
                </span>
            </div>

            <button mat-button type="button" class="btn-primary next-button"
                [disabled]="!formValid || validatingFile"
                (click)="buttonClickHandler()" >
                <div style="display: flex; flex: 1; align-items: center;">
                    {{text}}
                    <span *ngIf="text === 'Next'" class="chevron"></span>
                    <span class="spinning" *ngIf="text.includes('Saving') || text.includes('Requesting') || text.includes('Updating') || text.includes('Processing') || text.includes('Wait')">
                        <mat-progress-spinner
                        mode="indeterminate" diameter="25" strokeWidth="4"></mat-progress-spinner>
                    </span>
                </div>
            </button>
        </div>
    </div>
</div>
