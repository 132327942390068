declare let html2pdf: any;
import { inflateRaw } from 'pako';

interface ContactPersons {
  Title: string;
  FirstName: string;
  MiddleName: string;
  LastName: string;
  Phone: string;
  Email: string;
  ContactID: string;
}

interface Principal {
  FirstName: string;
  MiddleName: string;
  LastName: string;
  DeathCertImage: any;
}

// Session Data
interface Referree {
  Type: string;
  Code: string;
}

export interface RecordData {
  Title: string;
  FirstName: string;
  MiddleName: string;
  LastName: string;
  Phone: string;
  Email: string;
  Gender: string;
  Relationship: string;
  Other: string;

  DistributionAge: string;

  Nationality: string;
  Country: string;
  County: string;

  IDNo: string;
  KraPin: string;
  Occupation: string;
  Address: string;
  PostalAddress: string;

  IDNoImage: any;
  KraPinImage: any;

  GuardianType: string;

  GuardianBens: any;

  DOB: string;
  PercShare: any;

  BirthCertImage: any;

  InstitutionName: string;
  BusinessNature: string;
  OrgRegNum: string;
  OrgAddress: string;
  
  RegCertImage: any;
  CvImage: any;
  PassportImage: any;

  Category: string;
  SettlorID: any;
  BeneficiaryID: any;
  GuardianID: any;
  WitnessID: any;
  TestatorID: any;
  ExecutorID: any;
  EnforcerID: any;
  TrusteeID: any;
  
  // Trust Info
  TrustName: string;
  TrustPurpose: any;
  SourceOfFunds: any;
  UpkeepAmount: string;
  UpkeepFrequency: string;
  Bank: string;
  BankBranch: string;
  AccountName: string;
  AccountNo: string;
  AccountMandate: string;
  ModeOfContact: string;
  HowDidYouHearUS: string;
  OtherHear: string;
  AgentName: string;
  AgentPhoneNo: string;
  OtherPurpose: string;
  OtherSource: string;

  IDURL: string;
  KraURL: string;
  RegCertURL: string;
  CvURL: string;
  BirthCertURL: string;
  PassportURL: string;

  Principal: Principal;
  Authorisers: Array<ContactPersons>;
  ContactPersons: Array<ContactPersons>;
}

export interface SessionData {
  SessionId: string;
  Referree: Referree
};

export interface Intermediaries {
  ID: string;
  Description: string;
  Phone: string;
}

export interface TitleObject {
  Title: string;
}

export type UrlObject = { Url: string };

export function convertNumber(number: string): number {
  return Number(number);
}

export function formatDate(dateInput: string | Date | null | undefined): string {
  try {
    if (dateInput === null || dateInput === undefined) {
      return 'Invalid Date';
    }

    // Check if the input is a Date object, otherwise try to parse it as a string
    const inputDate = dateInput instanceof Date ? dateInput : new Date(dateInput);

    // Check if the parsed date is valid
    if (isNaN(inputDate.getTime())) {
      return 'Invalid Date';
    }

    const year = inputDate.getFullYear();
    const month = String(inputDate.getMonth() + 1).padStart(2, '0');
    const day = String(inputDate.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;

  } catch (error) {
    return 'Invalid Date';
  }
}

export function getTodayDayOfWeek(): string {
  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const today = new Date();
  const dayOfWeek = today.getDay();
  
  return daysOfWeek[dayOfWeek];
}
export function getEarliestDateFor18Years(): Date {
  const currentDate = new Date(); // Get the current date
  const earliestDate = new Date(); // Create a new date object to manipulate
  
  earliestDate.setFullYear(currentDate.getFullYear() - 18); // Subtract 18 years from the current date
  
  return earliestDate;
}

// Helper function to introduce a delay using Promises
export function delay(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function extractNumericValue(inputString: string | null | undefined): string {
  if (inputString === null || inputString === undefined) return '0';
  const numericPart = inputString.replace(/[^\d]/g, '');
  // Return the resulting numeric string
  return numericPart;
}

export function checkTime(time: number | null): boolean {
  // Get the current time in seconds
  if (time === null) return false;
  
  let currentTime = new Date().getTime() / 1000;

  let difference = currentTime - time;
  if (difference < 80) {
    return true;
  } else {
    return false;
  }
}

export function getCurrentTime(): string {
  const now = new Date();
  let hours = now.getHours();
  let minutes: number | string = now.getMinutes();
  const amOrPm = hours >= 12 ? 'PM' : 'AM';

  // Convert to 12-hour format
  hours = hours % 12;
  hours = hours ? hours : 12; // 0 should be displayed as 12

  // Add leading zero to minutes if needed
  minutes = minutes < 10 ? '0' + minutes : minutes;

  return hours + ':' + minutes + ' ' + amOrPm;
}

export function generateRandomNumber(): number {
  // Generate a random number between 0 and 1 (exclusive of 1)
  const randomNumber = Math.random();

  // Scale the random number to be within the range of 100000 to 999999
  const min = 100000;
  const max = 999999;
  const scaledRandomNumber = Math.floor(randomNumber * (max - min + 1)) + min;

  return scaledRandomNumber;
}

export function getCurrentTimePlus24HoursInMilliseconds(): number {
  // Get the current time in milliseconds
  const currentTimeInMilliseconds = new Date().getTime();

  // Add 24 hours in milliseconds (24 hours * 60 minutes * 60 seconds * 1000 milliseconds)
  const twentyFourHoursInMilliseconds = 24 * 60 * 60 * 1000;

  // Return the current time plus 24 hours in milliseconds
  return currentTimeInMilliseconds + twentyFourHoursInMilliseconds;
}
export function getCurrentTimePlus1WeekInMilliseconds(): number {
  // Get the current time in milliseconds
  const currentTimeInMilliseconds = new Date().getTime();

  // Add 24 hours in milliseconds (7 days * 24 hours * 60 minutes * 60 seconds * 1000 milliseconds)
  const oneWeekInMilliseconds = 7 * 24 * 60 * 60 * 1000;

  // Return the current time plus 24 hours in milliseconds
  return currentTimeInMilliseconds + oneWeekInMilliseconds;
}

export function hideEmailAddress(email: string): string {
  const atIndex = email.indexOf('@');
  if (atIndex > 0) {
    const username = email.substring(0, atIndex);
    const hiddenUsername = username.substring(0, Math.min(3, username.length)) + '***'; // Hide characters before @
    const domain = email.substring(atIndex); // Keep the domain unchanged
    return hiddenUsername + domain;
  } else {
    return email;
  }
}

export async function generatePDF(element: HTMLElement, pdfName: string): Promise<string> {
  let result = 'error';

  const options = {
    margin: [5, 5, 5, -90],
    filename: `${pdfName}.pdf`,
    image: { type: 'jpeg', quality: 0.98 },
    html2canvas: { scale: 2 },
    jsPDF: { unit: 'mm', format: 'letter', orientation: 'portrait', compress: true },
    pagebreak: { before: '.break', avoid: 'img' }
  };

  await html2pdf().set(options).from(element).toPdf().output('datauristring').then(function (pdfAsString: any) {
    // The PDF has been converted to a Data URI string and passed to this function.
    // console.log(pdfAsString);
    const base64String = pdfAsString.split(',')[1];
    result = base64String;
  }).catch((error: any) => {
    console.error('Error generating PDF:', error);
  });

  return result;
}

export function decodeSessionData(sessionData: string): SessionData | string {
  try {
    // Decode the Base64 URL-safe string
    const compressedData: Uint8Array = base64UrlDecode(sessionData);

    // Decompress the byte array
    const session: string = decompress(compressedData);

    // Parse the JSON data
    const decodedData: SessionData = JSON.parse(session);
    // console.log("SESSION DATA !!!\n", decodedData);
    return decodedData;
  } catch(error) {
    console.error('Error decoding session data:', error);
    return 'error';
  }
}

function decompress(data: Uint8Array): string {
  const decompressedStream = inflateRaw(data, { to: 'string' });
  return decompressedStream;
}

function base64UrlDecode(encodedData: string): Uint8Array {
  // Replace URL-safe characters and padding
  const base64 = encodedData.replace(/-/g, '+').replace(/_/g, '/').replace(/%3D/g, '=');
  // Decode Base64 string to byte array
  const binaryString = atob(base64);
  // Convert binary string to Uint8Array
  const bytes = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes;
}

export function getValueByIdOrDescription(value: string, objects: Intermediaries[], mode: 0 | 1 | 2): string {
  if (mode == 0) {
    // If value is a number (ID), find the corresponding object by ID
    const object = objects.find(obj => obj.ID === value);
    return object ? object.Description : '';

  } else if (mode == 1) {
    // If value is a string (Description), find the corresponding object by Description
    const object = objects.find(obj => obj.Description === value);
    return object ? object.Phone : '';
  } else {
    // If value is a string (Description), find the corresponding object by Description
    const object = objects.find(obj => obj.Description === value);
    return object ? object.ID : '';
  }

}

export function truncateString(input: string): string {
  // If the input is null, undefined, or an empty string, return it as is
  if (!input) {
      return input;
  }

  // Split the input string using the first space
  const [firstWord] = input.split(' ');

  if (firstWord.length > 14) {
    return firstWord.substring(0, 14);
  }

  // Return the first word
  return firstWord;
}

export function removeEmptyObjects (arr: Array<object>): Array<object> {
  return arr.filter(obj => Object.keys(obj).length !== 0);
};

// Tailored to organise beneficiaries
export function organizeValues(items: any, type: 'Beneficiary' | 'Guardian'): any[] {
  try {
    let output: any[] = [];

    for (let i=0; i<items.length; i++) {
      if (type === 'Beneficiary') {
        if (items[i].BeneficiaryID && items[i].Category && items[i].FirstName || items[i].InstitutionName) {
          let name = `${items[i].FirstName} ${items[i].LastName}`;
  
          if (items[i].Category.toLowerCase() === 'institution') name = items[i].InstitutionName;
  
          const item = {
            Description: name,
            ID: items[i].BeneficiaryID,
          };
  
          output.push(item);
        }

      } else {
        if (items[i].GuardianID && items[i].FirstName) {
          let name = `${items[i].FirstName} ${items[i].LastName}`;
  
          const item = {
            Description: name,
            ID: items[i].GuardianID,
            Beneficiaries: items[i].GuardianBens || []
          };
  
          output.push(item);
        }
      }
    }
    output.sort((a, b) => {
      const nameA = a.Description.toLowerCase();
      const nameB = b.Description.toLowerCase();
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;

      return 0;
    });

    return output;
  } catch (error) {
    console.log('Error : ', error);
    return [];
  }
}

 /**
* Compresses a JPG image if its size exceeds the given threshold (2MB in this case).
* 
* @param file - The input JPG file to be compressed
* @param maxSizeInMB - The maximum size allowed in MB (default 2MB)
* @returns A Promise that resolves to a compressed File object
*/
export async function compressImage(file: File, maxSizeInMB = 2): Promise<File> {
 const maxSizeInBytes = maxSizeInMB * 1024 * 1024;

 // Check if file size is already within the limit
 if (file.size <= maxSizeInBytes) {
   return file; // No need to compress
 }

 const image = await loadImage(file);
 const canvas = document.createElement('canvas');
 const ctx = canvas.getContext('2d');

 if (!ctx) {
   throw new Error('Could not create canvas context');
 }

 // Set canvas size to the image size
 canvas.width = image.width;
 canvas.height = image.height;

 // Draw the image on the canvas
 ctx.drawImage(image, 0, 0);

 let quality = 0.9; // Start with a high quality level
 let compressedBlob: Blob | null = null;

 // Reduce the image quality iteratively until it meets the size requirement
 while (quality > 0.1) {
   compressedBlob = await new Promise<Blob | null>((resolve) => {
     canvas.toBlob((blob) => resolve(blob), 'image/jpeg', quality);
   });

   if (compressedBlob && compressedBlob.size <= maxSizeInBytes) {
     break; // The file is now within the size limit
   }

   quality -= 0.1; // Reduce the quality level
 }

 if (!compressedBlob) {
   throw new Error('Could not compress the image to the desired size');
 }

 // Convert the Blob back to a File object
 return new File([compressedBlob], file.name, {
   type: 'image/jpeg',
   lastModified: Date.now(),
 });
}

/**
* Loads an image from a File object and returns an HTMLImageElement.
* 
* @param file - The input file to be loaded as an image
* @returns A Promise that resolves to an HTMLImageElement
*/
function loadImage(file: File): Promise<HTMLImageElement> {
 return new Promise((resolve, reject) => {
   const reader = new FileReader();
   const img = new Image();

   reader.onload = () => {
     img.src = reader.result as string;
   };

   img.onload = () => resolve(img);
   img.onerror = reject;

   reader.onerror = reject;

   reader.readAsDataURL(file);
 });
}


// export function organizeLinkIDs(beneficiaryIDs: number[] | string[], guardians: any): any[] { //
//   try {
//     let guardsBens = [];

//     if (beneficiaryIDs && beneficiaryIDs.length > 0) {
//       for (let i=0; i<beneficiaryIDs.length; i++) {
//         let id = '';

//         if (guardians.GuardianBens
//             && guardians.GuardianBens[i]
//             && guardians.GuardianBens[i].ID
//             && guardians.GuardianBens[i].ID !== ''
//             && guardians.GuardianBens[i].BeneficiaryID
//             && guardians.GuardianBens[i].BeneficiaryID === beneficiaryIDs[i].toString()
//           ) {

//             id = guardians.GuardianBens[i].ID;
//         }

//         const item = {
//           ID: id.toString(),
//           BeneficiaryID: beneficiaryIDs[i].toString()
//         };

//         guardsBens.push(item);
//       }
//     }

//     return guardsBens;
//   } catch (error) {
//     return [];
//   }
// }