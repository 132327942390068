import { trigger, transition, style, animate } from '@angular/animations';
import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { NotificationService } from 'src/app/services/notification.service';
import { TabControlService } from 'src/app/services/tab-control.service';
import { ValidationService } from 'src/app/services/validators/validation.service';
import { compressImage } from 'src/app/util/Helper';
import { environment } from 'src/environments/environment';
import * as Constants from "src/app/constants/constants";
import { ApiService } from 'src/app/services/api/api.service';
import { EstateControlService } from 'src/app/services/estate-control.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(-20px)' }),
        animate('300ms', style({ opacity: 1, transform: 'translateY(0)' })),
      ]),
    ]),
    trigger('bounceIn', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(0.5)' }),
        animate('300ms', style({ opacity: 1, transform: 'scale(1)' })),
      ]),
    ]),
  ],
})
export class InputComponent implements OnInit, OnChanges {
  @ViewChild('phoneInput')
  phoneInput!: NgxMatIntlTelInputComponent;

  @Input()
  controlName!: string;
  @Input()
  formGroup!: FormGroup;
  @Input()
  validationMessages: any;
  @Input()
  formErrors: any;

  @Input() loadingOptions: boolean = false;
  @Input() altIDSuggest: boolean = false;

  @Input()
  selectFunction: (param: any) => void = () => {};
  @Input()
  selectParam: any;

  @Input()
  fileFunction: (param: any) => void = () => {};

  @Input() runSuggestions: boolean = false;
  @Input()
  suggestFunction: (param1: 'ID' | 'NAME', param2: string) => void = () => {};
  @Input()
  suggestID: (param2: string) => void = () => {};

  @Input() inputType: string = 'normal';  // options : normal / select / multi-select / phone / date / area / file / view / auto-complete

  @Input() useFileURL: boolean = false;
  @Input() fileURL: string | null = null;

  @Input() type: string = 'text';
  @Input() label: string = 'label';
  @Input() hint: string = '';
  @Input() subtext: string = '';
  @Input() required: 'true' | 'false' = 'true';

  @Input() ArrayItems: any = ['Option one', 'Option two'];

  @Input() weekdaysOnly: boolean = false;
  @Input() includeSaturdays: boolean = false;

  @Input() enforceVirtualOfficeHours: boolean = false;
  @Input() enforcePhysicalOfficeHours: boolean = false;
  @Input() selectedVirtualOfficeDay: string = '';

  @Input() phoneError: string = '';

  @Input() maxDate: any = '';
  @Input() minDate: any = '';

  @Input() existingFileName: string = '';
  @Input() timeFunction: (param: any) => void = () => {}
  
  @Input() validationID: any = null;
  @Input() currentRecord: any = null;

  // @Output() file: EventEmitter<any> = new EventEmitter<any>();

  inputFocused: boolean = false;
  inputTouched: boolean = false;
  fileName: string = 'No file chosen';
  fileValid: boolean = false;
  validatingFile: boolean = false;

  filteredArray: any = [];

  time: string = '';
  ampm: 'AM' | 'PM' = 'AM';
  minHours: number = 8;
  maxHours: number  = 6;

  service: TabControlService | EstateControlService = this.tabControlService;
  isEstatePlanning: boolean = false;

  constructor(
    private validationService: ValidationService,
    private tabControlService: TabControlService,
    private estateControlService: EstateControlService,
    private notificationService: NotificationService,
    private apiService: ApiService,
    private router: Router,
    private toastr: ToastrService
    ) {
    }

  ngOnInit(): void {
    if (this.router.url.includes('estate-planning')) {
      this.service = this.estateControlService;
      this.isEstatePlanning = true;
    }

    if (!(this.existingFileName === '' || this.existingFileName === null)) {
      this.fileName = this.existingFileName;
      this.fileValid = true;
    }

    if (this.inputType === 'time') {
      const formControl = this.formGroup.get(this.controlName);
      if (formControl) {
        formControl.valueChanges.subscribe((value) => {
          if (value) {
            const newValue = value.replace(/[^0-9:]/g, '');

            if (newValue.length >=2) {
              const [hours, minutes] = newValue.split(':');
              let formattedHours = parseInt(newValue);
              let formattedMins = minutes;

              if (newValue.length > 3) {
                formattedHours = parseInt(hours);
                
                if (parseInt(minutes) >= 60) {
                  formattedMins = '00';
                }
              }

              if (formattedHours >= 12) {
                if (formattedHours > 12) {
                  formattedHours -= 12;
                } else {
                  formattedHours = 12;
                }
                this.ampm = 'PM';
              } else if (formattedHours === 0) {
                // formattedHours = 12;
                this.ampm = 'AM';
              }
              
              // Enforce Virtual Office Hours
              if (this.enforceVirtualOfficeHours) {
                if (this.selectedVirtualOfficeDay === 'Saturday') {
                  this.minHours = 9;
                  this.maxHours = 1;
                } else {
                  this.minHours = 8;
                  this.maxHours = 6;
                }

                if (this.ampm === 'AM' && formattedHours < this.minHours) {
                  formattedHours = this.minHours;
                  this.ampm = 'AM';
                } else if (this.ampm === 'PM' && formattedHours > this.maxHours && formattedHours != 12) {
                  formattedHours = this.maxHours;
                  this.ampm = 'PM';
                }
              }
              
              // Enforce Physical Office Hours
              if (this.enforcePhysicalOfficeHours) {
                if (this.ampm === 'AM' && formattedHours < 8) {
                  formattedHours = 8;
                } else if (this.ampm === 'PM' &&
                 (
                  formattedHours > 4 ||
                  formattedHours >= 4 &&
                  newValue.length > 3 && parseInt(minutes) > 30
                 )
                ) {
                  if (formattedHours != 12) formattedHours = 4;

                  if (formattedHours == 4 && newValue.length > 3 && parseInt(minutes) > 30) formattedMins = '30';
                }
              }
              
              this.timeFunction(this.ampm);

              if (formattedMins === undefined) {
                formattedMins = '00';
              }
              
              this.time = newValue.length === 2 ?
                `${formattedHours.toString().padStart(2, '0')}:` :
                `${formattedHours.toString().padStart(2, '0')}:${formattedMins}`;
              
              formControl.patchValue(this.time, { emitEvent: false });
            }
          }
        });
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    // Listen for changes in the formGroup and controlName inputs
    if ('formGroup' in changes && 'controlName' in changes) {
      const formControl = this.formGroup.get(this.controlName);

      // Subscribe to value changes of the form control
      if (formControl) {
        formControl.valueChanges.subscribe((newValue) => {
          // Capitalize the value before patching it back to the form control
          if (formControl.touched) {
            this.inputTouched = true;
          }
          
          if (typeof newValue === 'string') {
            let newControlValue;
            if (this.controlName.toLowerCase() === 'krapin' || this.controlName.toLowerCase() === 'idno') {
              newControlValue = newValue.replace(/[^A-Z0-9\s]/gi, '').replace(/[a-z]/g, (match) => match.toUpperCase());
              formControl.patchValue(newControlValue, { emitEvent: false });
              
              if (this.runSuggestions && this.controlName.toLowerCase() === 'idno') {
                if (this.altIDSuggest) this.suggestID(newValue);
                else this.suggestFunction('ID', newValue);
              }

            } else {
              if (this.controlName.toLowerCase().includes('email')) {
                newControlValue = newValue.toLowerCase();
                formControl.patchValue(newControlValue, { emitEvent: false });
              } else if (this.inputType.toLowerCase() == 'area') {
                newControlValue = newValue.replace(/[^A-Z0-9\s]/gi, '').replace(/(^\w)|(\.\s*\w)/g, match => match.toUpperCase()); // Capitalze after periods
                formControl.patchValue(newControlValue, { emitEvent: false });
              } else if (
                !(this.inputType.toLowerCase().includes('select')
                  || this.inputType.toLowerCase().includes('complete')
                  || this.inputType.toLowerCase().includes('phone')
                )
              ) {
                if(this.controlName.toLowerCase() === 'distributionage') {
                  newControlValue = newValue.replace(/^0+|[^0-9]/g, '');
                } else {
                  newControlValue = newValue
                  .replace(/[^A-Z0-9\s]/gi, '')
                  .toLowerCase()
                  .split(' ')
                  .map(word => {
                      if (word.includes("'")) {
                          return word.charAt(0).toUpperCase() + word.slice(1);
                      } else {
                          return word.charAt(0).toUpperCase() + word.slice(1);
                      }
                  })
                  .join(' ');
                }
                formControl.patchValue(newControlValue, { emitEvent: false });  
              }

              if (this.runSuggestions && this.controlName.toLowerCase() === ('firstname')) {
                  this.suggestFunction('NAME', newValue);
              }

            }
          }
        });
      }
    }
  }

  get formControl() {
    return this.formGroup.get(this.controlName);
  }

  get settlorType(): number {
    return this.tabControlService.settlorType;
  }

  onInputFocus(): void {
    this.inputFocused = true;
    if (this.inputType == 'auto-complete') this.filter();
  }

  onInputBlur(): void {
    if (this.controlName === 'PercShare') {
      const percentage = parseInt(this.formControl?.value);
      this.tabControlService.updateRemainingShares(percentage);
    }
    if (this.inputType == 'auto-complete') {
      this.filter();
      const value = this.formControl?.value.toLowerCase();
      let foundValue = '';
      this.filteredArray.some((item: any)=> {
        if (item.Description.toLowerCase() == value) {
          foundValue = item.Description;
        }
      })
      if (foundValue == '') {
        this.formControl?.setValue('');
      } else {
        this.formControl?.setValue(foundValue);
      }
    }

    try {
      if (this.inputType == 'phone' &&
        this.formControl?.value.length > 0 &&
        this.formControl?.value.length <= 6) {
        this.phoneInput.reset();
      }
    } catch(error) {}

    this.inputFocused = false;
    this.inputTouched = true;
  }

  filter(): void {
    if (this.ArrayItems.length > 0) {
      this.filteredArray = [];
      const filterValue = this.formControl?.value.toLowerCase();

      for (let i=0; i<this.ArrayItems.length; i++) {
        const item = this.ArrayItems[i];
        if (item.Description.toLowerCase().includes(filterValue)) {
          this.filteredArray.push(item);
        }
      }
    }
  }
  trackByFn(index: number, option: any): string {
    return option.Description;
  }

  capitalizeOption(option: string): string {
    try {
      const upper = option.toUpperCase();
      return upper;
    } catch(error) {
      console.log('Error capitalizing input');
      return option;
    }
  }

  isPdfOrJpg(fileName: string, includePDF: boolean = true): boolean {
    let allowedExtensions = ['.jpg', '.jpeg'];
    if (includePDF) allowedExtensions = ['.pdf', '.jpg', '.jpeg'];

    const ext = fileName.toLowerCase().substr(fileName.lastIndexOf('.'));
    return allowedExtensions.includes(ext);
  }

  onSelectionChange() {
    if (this.inputType === 'select') {
      this.selectFunction(this.selectParam); // Trigger the parent component's function
    }
  }

  onFileValid(fileObj: any, base64: string | null): void {
    try {
      const object = {
        name: fileObj.name,
        stringValue: base64
      };
      // console.log('OBJJJ ', fileObj);
      this.fileFunction(object);

    } catch (error) {
      console.log('!! File updater error : ', error);
    }
  }

  isLocalInput(): boolean {
    if (
      this.controlName === 'PaymentMode' ||
      this.controlName === 'referree' ||
      this.controlName === 'Category' ||
      this.controlName === 'Gender' ||
      this.controlName === 'HowDidYouHearUS' ||
      this.controlName === 'Relationship' ||
      this.controlName === 'Mortgage' ||
      this.controlName === 'AccountType' ||
      this.controlName === 'FinancierType' ||
      this.controlName === 'Ownership'
    ) {
      return true;
    }
    return false;
  }

  isTitle(): boolean {
    if (
      this.controlName === 'Title'
    ) {
      return true;
    }
    return false;
  }

  isCountry(): boolean {
    if (
      this.controlName === 'Country'
    ) {
      return true;
    }
    return false;
  }

  isCounty(): boolean {
    if (
      this.controlName === 'County'
    ) {
      return true;
    }
    return false;
  }

  isNationality(): boolean {
    if (
      this.controlName === 'Nationality'
    ) {
      return true;
    }
    return false;
  }

  isBank(): boolean {
    if (
      this.controlName.toLowerCase() === 'bank'
    ) {
      return true;
    }
    return false;
  }
  isBankBranch(): boolean {
    if (
      this.controlName.toLowerCase() === 'bankbranch'
    ) {
      return true;
    }
    return false;
  }

  // Filter function to disable weekends
  dateFilter = (d: Date | null): boolean => {
    const day = (d || new Date()).getDay();
    // Prevent Saturday and Sunday from being selected.

    if (this.includeSaturdays) {
      return day !== 0;
    } else {
      return day !== 0 && day !== 6;
    }
  };

  onTimeChange(event: any): void {
    this.ampm = event.value;
    this.timeFunction(this.ampm);

    const formControl = this.formGroup.get(this.controlName);
    if (formControl) {
      formControl.setValue('');
    }
  }

  openSavedDoc(): void {
    if (this.fileURL) {
      window.open(this.fileURL, "_blank");    
    }
  }

  enableSelectImage(): void {
    this.useFileURL = false;
    const formControl = this.formGroup.get(this.controlName);
    if (formControl) {
      formControl.setValue('');
    }
  }

  async openImageDialog(event: any): Promise<void> {
    try {
      const reader = new FileReader();
      
      const file = event.target.files[0];
      const fileName = file.name;
      let workFile: File = file;
      
      // Check if it's JPG
      if (this.isPdfOrJpg(fileName, false)) {
        const compressedFile = await compressImage(file);
        workFile = compressedFile;
      }
      if (!environment.production) console.log("Final Image\n", workFile);
      
      reader.onload = async () => {
        const base64String = reader.result ? reader.result.toString().split(',')[1] : null;
        // console.log(base64String);
        
        // Check the file size here
        const maxSizeInBytes = 2 * 1024 * 1024; // 2MB (you can adjust this value)
        const fileSizeInBytes = workFile.size;
        
        if (fileSizeInBytes <= maxSizeInBytes) {
          if (this.isPdfOrJpg(fileName)) {
            if (base64String) {
              if (this.controlName.toLowerCase().includes('idno') ||
                this.controlName.toLowerCase().includes('krapin')) {

                // Validate the attachment first
                const isValid = await this.validateDocument(base64String);

                if (isValid) {
                  this.fileName = fileName;
                  this.fileValid = true;
                  // Set value to formControl
                  this.onFileValid(file, base64String);
                  this.formControl?.setValue(base64String);
                } else {
                  this.fileName = 'Invalid Attachment !';
                  this.fileValid = false;
                  this.formControl?.setValue(null);
                }
              } else {
                this.fileName = fileName;
                this.fileValid = true;
                // Set value to formControl
                this.onFileValid(file, base64String);
                this.formControl?.setValue(base64String);
              }
            }
            
          } else {
            this.fileName = 'Invalid Format!';
            this.fileValid = false;
            this.formControl?.setValue(null);
            this.notificationService.viewToast('error', 'Please upload only PDF or JPG files');
          }
        } else {
          this.fileName = 'File too large!';
          this.fileValid = false;
          this.formControl?.setValue(null);
          this.notificationService.viewToast('error', 'The attached file is too large', 'The file size should not exceed 2MB');
        }
      };
      reader.readAsDataURL(workFile);
    }
    catch (error) {
      console.error("OpenImage ! ", error)
    }
  }

  async validateDocument(file: string): Promise<boolean> {
    this.toastr.clear();
    this.validatingFile = true;
    this.notificationService.isValidatingFile(true);
    let record = '';

    if (this.currentRecord) {
      record = this.currentRecord;
    } else {
      switch (this.service.activeTab) { // First case is milele trust
        case 1:
          record = 'Settlor';
          if (this.isEstatePlanning && this.estateControlService.planType == 1) record = 'Testator';
            
          break;
        case 2:
          record = 'Settlor';
          if (this.isEstatePlanning && this.estateControlService.planType == 1) record = 'Testator';
  
          break;
        case 3:
          record = 'Beneficiary';
          if (this.isEstatePlanning && this.estateControlService.planType == 1) record = 'Executor';
  
          break;
        case 4:
          record = 'Guardian';
          break;
        case 5:
          record = 'Trustee';
          if (this.isEstatePlanning && this.estateControlService.planType == 1) record = 'Beneficiary';
          break;
        case 6:
          record = 'Enforcer';
          if (this.isEstatePlanning && this.estateControlService.planType == 1) record = 'Witness';
          break;
        default:
          break;
      }
    }
    
    try {
      const data = {
        Record: record,
        DocType:
          this.controlName.toLowerCase().includes('idno') ? 'ID' :
          this.controlName.toLowerCase().includes('krapin') ? 'PIN' : 'REGCERT',
        ValidationID: this.validationID || '',
        MediaUrl: file
      };

      this.fileName = `Validating attached ${data.DocType}`;

      const response =  await this.apiService.postRequest(
        environment.baseUrl + Constants.validateDocURL, data, false);
      
      this.validatingFile = false;
      this.notificationService.isValidatingFile(false);
      if (response.Status === 1) {
        return true;
      } else {
        this.notificationService.viewToast('error', 'Attachment not verified', response.Message, true);
        return false;
      }

    } catch (ex) {
      this.fileName = 'No file chosen';
      console.error('Validate Doc error : ', ex);
      this.validatingFile = false;
      this.notificationService.isValidatingFile(false);
      return false;
    }
  }
  
}
