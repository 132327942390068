export const ICEA_FUND_NAME : string = 'ICEA LION TRUST COMPANY LIMITED - MILELE A/C';
export const MILELE_ACCOUNT_NUM: string = '1904210019';
export const ICEA_BANK: string = 'NCBA BANK KENYA plc';
export const ICEA_BANK_BRANCH: string = 'CITY CENTER';
export const ICEA_BANK_CODE: string = '101';
export const ICEA_BRANCH_CODE: string = '07000';
export const ICEA_CLEARING: string = 'CBAFKENX';

export const PAYBILL_TRUST: number = 4230088;
export const PAYBILL_ESTATE: number = 4230099;

export const estatePlanningBrochureName: string = 'Estate Planning.pdf';
export const trustFundBrochureName: string = 'Trust Fund.pdf';

// Estate Planning
export const ICEA_ESTATE_NAME : string = 'ICEA LION TRUST COMPANY LIMITED';
export const ESTATE_ACCOUNT_NUM: string = '1904210066';
export const TRUST_CREATION_FEE: number = 350000;
export const LEGAL_REVIEW_FEE: number = 50000;
export const TAX_CONSULTATION_FEE: number = 60000;
export const INVESTMENT_CONSULTATION_FEE: number = 20000;
// Will
export const WILL_CREATIION_FEE: number = 50000;

// URL PATHS:
const path = '/Api';

export const otpRequestURL: string = `${path}/otp/request`;
export const otpValidateURL: string = `${path}/otp/validate`;

export const banksURL: string = `${path}/data/banks`;
export const bankBranchesURL: string = `${path}/data/banks/branches`;

export const fundSourceURL: string = `${path}/data/fund-source`;
export const purposeURL: string = `${path}/data/trust-purpose`;
export const instructorOptURL: string = `${path}/data/account-mandate`;
export const prefModeURL: string = `${path}/data/preffered-modes`; // missing
export const upkeepURL: string = `${path}/data/upkeep`;
export const businessNatureURL: string = `${path}/data/business-nature`;

export const salutationsURL: string = `${path}/data/salutations`;
export const countriesURL: string = `${path}/data/countries`;
export const countiesURL: string = `${path}/data/counties`;

export const consultantsURL: string = `${path}/data/consultants`;
export const intermediaryURL: string = `${path}/data/intermediaries?`;

export const saveSettlorURL: string = `${path}/save/settlor`;
export const saveBenURL: string = `${path}/save/beneficiary`;
export const saveGuardianURL: string = `${path}/save/guardian`;
export const saveTrustURL: string = `${path}/save/trust`;

export const linkGuardianBeneficiaryURL: string = `${path}/link/guardian-beneficiary`;

export const deleteRecordURL: string = `${path}/records/remove`;

export const continueLaterURL: string = `${path}/progress/save`;
export const continueNowURL: string = `${path}/progress/resume`;
export const bookCallURL: string = `${path}/help/book-call`;

export const mpesaPaymentURL: string = `${path}/payment/mpesa/initiate`;
export const mpesaConfirmURL: string = `${path}/payment/mpesa/confirm`;
export const rtgsPaymentURL: string = `${path}/payment/rtgs`;

// Estate Planning
export const bookAppointmentEstateURL: string = `${path}/estate-plan/help/book-appointment`;
export const saveAppointmentSettlorsEstateURL: string = `${path}/estate-plan/help/book-appointment/settlors`;

export const legalConsultationURL: string = `${path}/estate-plan/legal-consultation`;

// Private Trusts
export const estateBeneficiaryURL: string = `${path}/estate-plan/beneficiary`;
export const estateGuardianURL: string = `${path}/estate-plan/guardian`;
export const estateAssetsURL: string = `${path}/estate-plan/assets`;
export const confirmMPESAPaymentURL: string = `${path}/estate-plan/mpesa/confirm`;

export const privateSettlorURL: string = `${path}/private-trust/settlor`;
export const privateTrusteeURL: string = `${path}/private-trust/trustees`;
export const privateEnforcerURL: string = `${path}/private-trust/enforcer`;
export const privateTrustDetailsURL: string = `${path}/private-trust/save`;

export const willTestatorURL: string = `${path}/will/testator`;
export const willExecutorURL: string = `${path}/will/executor`;
export const willWitnessURL: string = `${path}/will/witness`;
export const willDetailsURL: string = `${path}/will/save`;

export const cacheEstateURL: string = `${path}/estate-plan/save`;
export const resumeEstateURL: string = `${path}/estate-plan/resume`;

export const validateDocURL: string = `${path}/validate/document`;
