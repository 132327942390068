<div class = "editor-container">
    <div class="editor-overlay">
        <div class="editor-content">
            <div class="top-part">
                <div class="close-button" (click)="closePopup()">
                    <mat-icon fontIcon="close" aria-hidden="false" aria-label="Close button"></mat-icon>
                </div>
                <h3 class="blue left-15">{{title}}</h3>
            </div>
            <!-- CONTENT -->
            <form (ngSubmit)="onConfirmClick()"
                [formGroup]="editorForm"
                class="editor-form"
                autocomplete="off"
                novalidate
            >
                <div *ngIf="recordType != 'Asset'" class="flex column">
                    <!-- CATEGORY -->
                    <div class="row half" *ngIf="recordType === 'Guardian' && recordIndex !== 0">    
                        <app-input [formGroup]="editorForm" controlName="Category"
                            inputType="select"
                            [ArrayItems]="GuardianOptions"
                            [label]="guardianTypeLabel"
                            [hint]="guardianTypeHint"
                            [validationMessages]="validationMessages" [formErrors]="formErrors">
                        </app-input>
                    </div>
                    <!-- FIRSTNAME - EMAIL -->
                    <div *ngIf="!(editorForm.get('Category')?.value.toLowerCase().includes('institution') || editorForm.get('Category')?.value.toLowerCase().includes('corporate') )">
                        <div class="row-alt">
                            <div class="row-mini">
                                <app-input [formGroup]="editorForm" controlName="Title"
                                    label={{titleLabel}} hint={{titleHint}}
                                    inputType="select"
                                    [loadingOptions]="loadingTitles"
                                    [ArrayItems]="TitleOptions"
                                    required="false"
                                    [validationMessages]="validationMessages" [formErrors]="formErrors">
                                </app-input>
            
                                <app-input [formGroup]="editorForm" controlName="FirstName"
                                    label={{fNameLabel}} hint={{fNameHint}}
                                    [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                                
                            </div>
                            <div class="row-mini">
                                <app-input [formGroup]="editorForm" controlName="MiddleName"
                                    label={{mNameLabel}} hint={{mNameHint}} required='false'
                                    [validationMessages]="validationMessages" [formErrors]="formErrors" ></app-input>
                                <app-input [formGroup]="editorForm" controlName="LastName"
                                    label={{surnameLabel}} hint={{surnameHint}}
                                    [validationMessages]="validationMessages" [formErrors]="formErrors" ></app-input>
                            </div>
                        </div>
                        <div class="row">
                            <app-input [formGroup]="editorForm" controlName="Phone"
                                label={{phoneLabel}} inputType="phone" [required]="recordType === 'Beneficiary' ? 'false' : 'true'"
                                [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                            <app-input [formGroup]="editorForm" controlName="Email"
                                label={{emailLabel}} hint={{emailHint}} type="email" [required]="recordType === 'Beneficiary' ? 'false' : 'true'"
                                [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                        </div>

                        <div *ngIf="record.Relationship && record.Relationship.length > 0">
                            <div class="row" [ngClass]="{'half': editorForm.get('Relationship')?.value != 'Other'}">
                                <app-input [formGroup]="editorForm"
                                    class="grid-item"
                                    controlName="Relationship"
                                    inputType="select"
                                    [ArrayItems]="RelationOptions"
                                    label={{relationLabel}} hint={{relationHint}}
                                    [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                                <app-input [formGroup]="editorForm" controlName="Other"  *ngIf="editorForm.get('Relationship')?.value == 'Other'"
                                    label='Specify Relationship' hint='Specify your relationship'
                                    [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                            </div>
                        </div>

                        <div *ngIf="recordType != 'Beneficiary'">
                            <div class="row" [ngClass]="{'half': !(record.KraPin && record.KraPin.length > 0)}">
                                <app-input
                                    [formGroup]="editorForm" controlName="IDNo"
                                    class="grid-item" label={{idLabel}} hint={{idLabel}}
                                    [validationMessages]="validationMessages" [formErrors]="formErrors">
                                </app-input>
                                <app-input [formGroup]="editorForm" controlName="KraPin"  *ngIf="record.KraPin && record.KraPin.length > 0"
                                    class="grid-item" label={{kraPinLabel}} hint={{kraPinHint}}
                                    [validationMessages]="validationMessages" [formErrors]="formErrors">
                                </app-input>
                            </div>
                            <div class="row half" *ngIf="recordType == 'Witness' && record.PostalAddress && record.PostalAddress.length > 0">
                                <app-input
                                    [formGroup]="editorForm" controlName="PostalAddress"
                                    class="grid-item" label={{postaLabel}} hint={{postaHint}}
                                    [validationMessages]="validationMessages" [formErrors]="formErrors">
                                </app-input>
                            </div>
                        </div>
                        
                    </div>
                    
                    <!-- DOB - GENDER -->
                    <div *ngIf="recordType === 'Beneficiary' && editorForm.get('Category')?.value !== 'Institution'">
                        <div class="row">
                            <app-input [formGroup]="editorForm" controlName="Gender"
                                label={{genderLabel}} hint={{genderHint}}
                                inputType="select"
                                [ArrayItems]="GenderOptions"
                                [validationMessages]="validationMessages" [formErrors]="formErrors">
                            </app-input>

                            <app-input [formGroup]="editorForm" controlName="DOB"
                                class="grid-item"
                                label={{dobLabel}}
                                hint={{dobHint}}
                                [minDate]="minDate"
                                [maxDate]="maxDate"
                                inputType="date"
                                [validationMessages]="validationMessages"
                                [formErrors]="formErrors"></app-input>
                        </div>
                    </div>
                    <!-- ADDRESS -->
                    <div *ngIf="recordType != 'Beneficiary' && recordType != 'Witness' && recordType != 'Guardian'">
                        <div class="row" [ngClass]="{'half': recordType =='Trustee' || recordType == 'Enforcer'}">
                            <app-input *ngIf="recordType !='Trustee'"
                                [formGroup]="editorForm" controlName="Address"
                                class="grid-item" [label]="recordType == 'Enforcer' ? postaLabel : addressLabel" [hint]="recordType == 'Enforcer' ? postaHint : addressHint"
                                [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                            <app-input *ngIf="record.PostalAddress && record.PostalAddress.length > 0"
                                [formGroup]="editorForm" controlName="PostalAddress"
                                class="grid-item" label={{postaLabel}} hint={{postaHint}}
                                [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                        </div>
                    </div>
                    <!-- INSTITUTION NAME - ADDRESS -->
                    <div *ngIf="editorForm.get('Category')?.value.toLowerCase().includes('institution') || editorForm.get('Category')?.value.toLowerCase().includes('corporate')">
                        <div class="row">
                            <app-input [formGroup]="editorForm" controlName="InstitutionName"
                                class="grid-item" label={{institutionNameLabel}} hint={{institutionNameHint}}
                                [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>

                            <app-input [formGroup]="editorForm" controlName="BusinessNature"
                                class="grid-item" label={{businessNatureLabel}} hint={{businessNatureHint}}
                                inputType="select"
                                [ArrayItems]="BusinessNatureOptions"
                                [loadingOptions]="loadingBizNature"
                                [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                        </div>

                        <div class="row">
                            <app-input
                                [formGroup]="editorForm" controlName="OrgRegNum"
                                class="grid-item" label={{orgRegNumLabel}} hint={{orgRegNumHint}}
                                [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>

                            <app-input [formGroup]="editorForm" controlName="KraPin"
                                class="grid-item" label={{kraPinLabel}} hint={{kraPinHint}}
                                [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                        </div>

                        <div class="row half">
                            <app-input [formGroup]="editorForm" controlName="OrgAddress" *ngIf="recordType === 'Beneficiary' "
                                class="grid-item" label={{orgAddressLabel}} hint={{orgAddressHint}}
                                [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                            <app-input [formGroup]="editorForm" controlName="PostalAddress" *ngIf="recordType === 'Trustee' "
                                class="grid-item" label="Organisation Physical Address" hint="Enter Physical Address"
                                [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                        </div>
                    </div>

                    <!-- ID DOC - KRA DOC -->
                    <div *ngIf="recordType === 'Beneficiary' || recordType == 'Trustee'"
                        class="row" [ngClass]="{'half': recordType === 'Beneficiary' && editorForm.get('Category')?.value.toLowerCase().includes('individual') && editorForm.get('DOB')?.value != '' && getDateDifference(editorForm.get('DOB')?.value) === false}" >
                        
                        <app-input [formGroup]="editorForm" controlName="BirthCertImage"
                            *ngIf="recordType === 'Beneficiary'
                                && editorForm.get('Category')?.value === 'Individual'
                                && editorForm.get('DOB')?.value !== ''
                                && getDateDifference(editorForm.get('DOB')?.value) === false"
                            class="grid-item" label={{birthCertLabel}} inputType="file"
                            [fileFunction]="updateBirthCertObject.bind(this)"
                            [existingFileName]="record.BirthCertImage && record.BirthCertImage != '' ? record.BirthCertImage.name: ''"
                            [useFileURL]="!record.BirthCertImage || record.BirthCertImage == '' && !birthCertObject ? true : false"
                            [fileURL]="record.BirthCertURL"
                            [validationMessages]="validationMessages" [formErrors]="formErrors">
                        </app-input>

                        <app-input [formGroup]="editorForm" controlName="IDNoImage"
                            *ngIf="(recordType == 'Trustee' && editorForm.get('Category')?.value.toLowerCase().includes('individual')) ||
                                (recordType == 'Beneficiary' &&
                                editorForm.get('Category')?.value === 'Individual'
                                && editorForm.get('DOB')?.value !== ''
                                && getDateDifference(editorForm.get('DOB')?.value) === true)
                            "
                            class="grid-item" label={{idFileLabel}} inputType="file"
                            [validationID]="validationID"
                            [currentRecord]="recordType"
                            [existingFileName]="record.IDNoImage && record.IDNoImage != '' ? record.IDNoImage.name : ''"
                            [fileFunction]="updateIDObject.bind(this)"
                            [useFileURL]="!record.IDNoImage || record.IDNoImage == '' && !idObject ? true : false"
                            [fileURL]="record.IDURL"
                            [validationMessages]="validationMessages" [formErrors]="formErrors">
                        </app-input>

                        <app-input [formGroup]="editorForm" controlName="RegCertImage"
                            *ngIf="editorForm.get('Category')?.value.toLowerCase().includes('institution')
                                || editorForm.get('Category')?.value.toLowerCase().includes('corporate')
                            "
                            class="grid-item" label={{regCertFileLabel}} inputType="file"
                            [existingFileName]="record.RegCertImage && record.RegCertImage != '' ? record.RegCertImage.name: ''"
                            [fileFunction]="updateRegCertObject.bind(this)"
                            [useFileURL]="!record.RegCertImage || record.RegCertImage == '' && !regCertObject ? true : false"
                            [fileURL]="record.RegCertURL"
                            [validationMessages]="validationMessages" [formErrors]="formErrors">
                        </app-input>

                        <app-input [formGroup]="editorForm" controlName="KraPinImage"
                            *ngIf="recordType == 'Trustee' ||
                                (
                                    recordType == 'Beneficiary' &&
                                    (
                                        editorForm.get('Category')?.value.toLowerCase().includes('institution') ||
                                        editorForm.get('Category')?.value === 'Individual'
                                        && editorForm.get('DOB')?.value !== ''
                                        && getDateDifference(editorForm.get('DOB')?.value) === true
                                ))
                            "
                            class="grid-item" label={{kraFileLabel}} inputType="file"
                            [validationID]="validationPIN"
                            [currentRecord]="recordType"
                            [existingFileName]="record.KraPinImage && record.KraPinImage != '' ? record.KraPinImage.name: ''"
                            [fileFunction]="updateKraObject.bind(this)"
                            [useFileURL]="!record.KraPinImage || record.KraPinImage == '' && !kraObject ? true : false"
                            [fileURL]="record.KraURL"
                            [validationMessages]="validationMessages" [formErrors]="formErrors">
                        </app-input>
                    </div>

                    <div class="row" *ngIf="!(recordType === 'Beneficiary' || recordType == 'Trustee' || recordType == 'Executor')">
                        <app-input [formGroup]="editorForm" controlName="IDNoImage"
                            class="grid-item" label={{idFileLabel}} inputType="file"
                            [validationID]="validationID"
                            [currentRecord]="recordType"
                            [existingFileName]="record.IDNoImage && record.IDNoImage != '' ? record.IDNoImage.name: ''"
                            [fileFunction]="updateIDObject.bind(this)"
                            [useFileURL]="!record.IDNoImage || record.IDNoImage == '' && !idObject ? true : false"
                            [fileURL]="record.IDURL"
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                        <app-input
                            [formGroup]="editorForm" class="grid-item"
                            controlName="KraPinImage" label={{kraFileLabel}} inputType="file"
                            [validationID]="validationPIN"
                            [currentRecord]="recordType"
                            [existingFileName]="record.KraPinImage && record.KraPinImage != '' ? record.KraPinImage.name: ''"
                            [fileFunction]="updateKraObject.bind(this)"
                            [useFileURL]="!record.KraPinImage || record.KraPinImage == '' && !kraObject ? true : false"
                            [fileURL]="record.KraURL"
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                    </div>

                    <div *ngIf="recordType != 'Executor' && (recordType == 'Settlor' || recordType == 'Witness' || (recordType == 'Trustee' && editorForm.get('Category')?.value.toLowerCase().includes('individual') ) ) ">
                        
                        <div class="row"  [ngClass]="{'half': recordType !== 'Trustee'}">
                            <app-input [formGroup]="editorForm" controlName="PassportImage"
                                class="grid-item" label={{passportLabel}} inputType="file"
                                [existingFileName]="record.PassportImage && record.PassportImage != '' ? record.PassportImage.name: ''"
                                [fileFunction]="updatePassportObject.bind(this)"
                                [useFileURL]="!record.PassportImage || record.PassportImage == '' && !passportObject ? true : false"
                                [fileURL]="record.PassportURL"
                                [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                            <app-input *ngIf="recordType == 'Trustee'"
                                [formGroup]="editorForm" class="grid-item"
                                controlName="CvImage" label={{cvLabel}} inputType="file"
                                [existingFileName]="record.CvImage && record.CvImage != '' ? record.CvImage.name: ''"
                                [fileFunction]="updateCVObject.bind(this)"
                                [useFileURL]="!record.CvImage || record.CvImage == '' && !cvObject ? true : false"
                                [fileURL]="record.CvURL"
                                [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                        </div>
                        
                    </div>

                    <!-- CONTACT PERSONS -->
                    <div formArrayName="ContactPersons" class="flex column contacts"
                        *ngIf="(recordType === 'Beneficiary' || recordType === 'Trustee')
                            && (editorForm.get('Category')?.value.toLowerCase().includes('institution') || editorForm.get('Category')?.value.toLowerCase().includes('corporate') )
                        "
                    >
                        <span class="blue lead" style="font-size: 1.3rem; margin-left: 1.5%; margin-bottom: 15px;">
                            {{ 'Contact Persons' }}
                        </span>
                        <div class="line-2" style="background-color: #00518a;"></div>
                        <div *ngFor="let contact of getContacts().controls; let j=index" [formGroupName]="j" style="position: relative;">
                            <div style="height: 10px;" *ngIf="j > 1"></div>
            
                            <p style="font-weight: 600; font-size: 1rem; margin-left: 1.5%;" class="lead">
                                {{ 'Contact Person ' + (j+1)}}
                            </p>

                            <div class="row-alt">
                                <div class="row-mini">
                                    <app-input [formGroup]="getContactsFormGroup(j)" controlName="Title"
                                        label={{titleLabel}} hint={{titleHint}}
                                        inputType="select"
                                        [loadingOptions]="loadingTitles"
                                        [ArrayItems]="TitleOptions"
                                        required="false"
                                        [validationMessages]="validationMessages" [formErrors]="formErrors">
                                    </app-input>
                
                                    <app-input [formGroup]="getContactsFormGroup(j)" controlName="FirstName"
                                        label={{fNameLabel}} hint={{fNameHint}}
                                        [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                                    
                                </div>
                                
                                <div class="row-mini">
                                    <app-input [formGroup]="getContactsFormGroup(j)" controlName="MiddleName"
                                    label={{mNameLabel}} hint={{mNameHint}} required='false'
                                    [validationMessages]="validationMessages" [formErrors]="formErrors" ></app-input>
                                <app-input [formGroup]="getContactsFormGroup(j)" controlName="LastName"
                                    label={{surnameLabel}} hint={{surnameHint}}
                                    [validationMessages]="validationMessages" [formErrors]="formErrors" ></app-input>
                                </div>
                                
                            </div>
                            
                            <div class="row">
                                <app-input [formGroup]="getContactsFormGroup(j)" controlName="Phone"
                                    class="grid-item" label={{phoneLabel}}  inputType="phone"
                                    [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                                <app-input [formGroup]="getContactsFormGroup(j)" controlName="Email"
                                    class="grid-item" label={{emailLabel}} hint={{emailLabel}}
                                    [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                            </div>

                            <div class="line-2" ></div>
                            
                            <div class="remove-button" *ngIf="j > 0">    
                                <button mat-stroked-button type="button" (click)="removeContact(j)">
                                    <div style="display: flex; flex: 1; align-items: center;">
                                        <span class="button-text">
                                            <u>{{ removeContactText }}</u>
                                        </span>
                                    </div>
                                </button>
                            </div>
                        </div>

                        <div class="blueStroke row" >
                            <button mat-stroked-button
                                [disabled]="!editorForm.valid"
                                type="button" (click)="addNewContact()">
                                <span class="button-text">
                                    {{ '+ Add Contact Person'}}
                                </span>
                            </button>
                        </div>
                        
                    </div>
                </div>

                <div *ngIf="recordType == 'Asset'">
                    <app-step4-assets [showBottomButtons]="false" class="subcontent"></app-step4-assets>
                </div>
            
                <!-- BOTTOM OF FORM -->
                <div class="editor-buttons">
                    <button mat-button class="confirm"
                        (click)="onConfirmClick()"
                        [ngClass]="{'loadingButton': loading}"
                        [disabled]="loading || recordType != 'Asset' && !editorForm.valid">

                        <div style="display: flex; width: 100%; align-items: center;">
                            {{loading ? loadingText : 'Done'}}
                        
                            <span class="spinning" *ngIf="loading">
                                <mat-progress-spinner
                                    mode="indeterminate"
                                    diameter="25" strokeWidth="4">
                                </mat-progress-spinner>
                            </span>
                        </div>
                    </button>

                    <button class = "cancel" mat-button type="button" (click)="closePopup()">Cancel</button>
                </div>
            </form>
        </div>
    </div>
</div>
