import { CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api/api.service';
import { EstateControlService } from 'src/app/services/estate-control.service';
import { NotificationService } from 'src/app/services/notification.service';
import { TabControlService } from 'src/app/services/tab-control.service';
import { ValidationService } from 'src/app/services/validators/validation.service';
import { RecordData, UrlObject } from 'src/app/util/Helper';

import { environment } from 'src/environments/environment';
import * as Constants from "src/app/constants/constants";

@Component({
  selector: 'app-estate-editor',
  templateUrl: './estate-editor.component.html',
  styleUrls: ['./estate-editor.component.scss']
})
export class EstateEditorComponent implements OnInit {
  @Output() close = new EventEmitter<void>();

  @Input() recordType: 'Settlor' | 'Beneficiary' | 'Asset' | 'Trustee' | 'Enforcer' | 'Guardian' | 'Testator' | 'Executor' | 'Witness' | null = null;
  @Input() recordIndex: number = 0;
  @Input() parentIndex: number = 0;
  @Input()
  record!: RecordData;


  BranchPlaceHolders: any = [];

  title: string = `Edit ${this.recordType} Information`;

  titleLabel: string = 'Title';
  titleHint: string = 'Title';
  fNameLabel: string = 'First Name';
  fNameHint : string = 'First name';
  mNameLabel : string = 'Middle Name';
  mNameHint : string = 'Middle name';
  surnameLabel : string = 'Surname';
  surnameHint : string = 'Last name';
  phoneLabel : string = 'Primary Phone Number';
  emailLabel : string = 'Email Address';
  emailHint : string = 'Email address';

  genderLabel: string = 'Gender';
  genderHint: string = 'Select gender';

  // Beneficiary specific
  dobLabel: string = 'Date of birth';
  dobHint: string = '01/01/1990';
  shareAllocationLabel: string = 'Share Allocation (%)';
  birthCertLabel: string = 'Upload Birth Certificate of Child';
  shareAllocationSubtext: string = 'Share allocation for all beneficiaries should total 100%';

  idLabel: string = 'ID/Passport Number';
  idHint: string = 'Enter ID or Passport Number';
  kraPinLabel: string = 'KRA PIN';
  kraPinHint: string = 'Enter KRA PIN';
  addressLabel: string = 'Physical Address';
  addressHint: string = 'Enter Physical address';
  postaLabel: string = 'Postal Address';
  postaHint: string = 'Enter Physical address';
  idFileLabel: string = 'Upload copy of ID/Passport';
  kraFileLabel: string = 'Upload KRA PIN';
  regCertFileLabel: string = 'Upload Registration Certificate';

  passportLabel: string = 'Upload Passport Photo';
  cvLabel: string = 'Upload Curriculum Vitae';

  relationLabel: string = 'Relationship To Settlor';
  relationHint: string = 'Specify Relationship';

  nationalityLabel: string = 'Nationality';
  nationalityHint: string = 'Select nationality';
  countryLabel: string = 'Country of Residence';
  countryHint: string = 'Select country';
  countyLabel: string = 'County';
  countyHint: string = 'Select county';

  institutionNameLabel: string = 'Name of Institution';
  businessNatureLabel: string = 'Nature of Business';
  orgRegNumLabel: string = 'Organization Registration Number';
  orgAddressLabel: string = 'Organization Physical Address';

  institutionNameHint: string = 'Enter name';
  businessNatureHint: string = 'Enter nature of business';
  orgRegNumHint: string = 'Enter registration Number';
  orgAddressHint: string = 'Enter physical address';
  
  guardianTypeLabel: string = 'Guardian Type';
  guardianTypeHint: string = 'Select guardian type';

  trusteeTypeLabel: string = 'Select Trustee Type';
  trusteeTypeHint: string = 'Trustee type';

  benTypeLabel: string = 'Select Beneficiary Type';
  benTypeHint: string = 'Beneficiary type';

  selectText: string = 'Select one';
  removeContactText: string = 'Remove Contact';

  requiredFieldString: string = 'Required field';
  invalidDocString: string = 'Please attach a valid document';


  loadingText: string = `Updating ${this.recordType} Details`;
  loadingBankBranches: boolean = false;
  bankBranchesEmpty: boolean = true;

  validationID: any = null;
  validationPIN: any = null;

  formErrors: { [key: string]: string } = {
    FirstName: '',
    MiddleName: '',
    LastName: '',
    Phone: '',
    Email: '',

    BirthCertImage: '',
    DOB: '',

    Relationship: '',
    Other: '',

    IDNo: '',
    KraPin: '',
    IDNoImage: '',
    KraPinImage: '',

    Category: '',

    Title: '',
    Gender: '',
    InstitutionName: '',
    BusinessNature: '',
    OrgRegNum: '',
    OrgAddress: '',
    PostalAddress: '',
    Address: '',
    Nationality: '',
    Country: '',
    County: '',
    RegCertImage: '',
    
  };

  validationMessages: { [key: string]: {} } = {
    FirstName: { required: this.requiredFieldString },
    MiddleName: { required: this.requiredFieldString },
    LastName: { required: this.requiredFieldString },
    Phone: { required: this.requiredFieldString, pattern:'Invalid phone number' },
    Email: { required: this.requiredFieldString, email: "Invalid email address" },

    DOB:  { required: this.requiredFieldString },
    BirthCertImage: { required: this.requiredFieldString, invalid: this.invalidDocString },

    Relationship: {required: this.requiredFieldString},
    Other: {required: this.requiredFieldString},

    IDNo:  { required: this.requiredFieldString, min:'Invalid ID or Passport number' },
    KraPin: { required: this.requiredFieldString, pattern:'Invalid KRA PIN' },
    IDNoImage: { required: this.requiredFieldString, invalid: this.invalidDocString },
    KraPinImage: { required: this.requiredFieldString, invalid: this.invalidDocString },

    Category: { required: this.requiredFieldString },
    // Trust knfo
    
    Title: { required: this.requiredFieldString },
    Gender: { required: this.requiredFieldString },
    InstitutionName: { required: this.requiredFieldString },
    BusinessNature: { required: this.requiredFieldString },
    OrgRegNum: { required: this.requiredFieldString },
    OrgAddress: { required: this.requiredFieldString },
    PostalAddress: { required: this.requiredFieldString },
    Address: { required: this.requiredFieldString },
    Nationality: { required: this.requiredFieldString },
    Country: { required: this.requiredFieldString },
    County: { required: this.requiredFieldString },
    RegCertImage: { required: this.requiredFieldString },
  };

  editorForm = this._formBuilder.group({
    Title: [''],
    FirstName: [''],
    MiddleName: [''],
    LastName: [''],
    Phone: [''],
    Email: [''],
    Gender: [''],
    Category: [''],
    Relationship: [''],
    Other: [''],

    DOB: [''],

    BirthCertImage: [],

    InstitutionName: [''],
    BusinessNature: [''],
    OrgRegNum: [''],
    OrgAddress: [''],

    IDNo: [''],
    KraPin: [''],
    PostalAddress: [''],
    Address: [''],

    IDNoImage: [],
    KraPinImage: [],
    RegCertImage: [],
    PassportImage: [],
    CvImage: []
  }) as FormGroup & { [key: string]: AbstractControl };;


  constructor (
    private _formBuilder: FormBuilder,
    private notificationService: NotificationService,
    private tabControlService: TabControlService,
    private estateControlService: EstateControlService,
    private validationService: ValidationService,
    private apiService: ApiService,
    private currencyPipe: CurrencyPipe,
    private route: ActivatedRoute
  ) {
    
  }

  ngOnInit(): void {
    console.log(this.record);
    this.title = `Edit ${this.recordType} Information`;
    this.loadingText = `Updating ${this.recordType} Details`;
    
    // update form values before editing
    if (this.recordType !== 'Asset') {
      this.populateData();
      if (this.editorForm && this.record) {
        this.editorForm.patchValue({
          Title: this.record.Title || '',
          FirstName: this.record.FirstName,
          MiddleName: this.record.MiddleName || '',
          LastName: this.record.LastName || '',
          Phone: this.record.Phone || '',
          Email: this.record.Email || '',
          PostalAddress: this.record.PostalAddress || '',
          Address: this.record.Address || '',
          IDNo: this.record.IDNo || '',
          KraPin: this.record.KraPin || '',
          Relationship: this.record.Relationship && this.record.Relationship.length > 0 ? this.record.Relationship : '',
          Other: this.record.Other && this.record.Other.length > 0 ? this.record.Other : '',
          Gender: this.record.Gender || '',
          DOB: this.record.DOB ? new Date(this.record.DOB) : '',
          
          InstitutionName: this.record.InstitutionName || '',
          BusinessNature: this.record.BusinessNature,
          OrgRegNum: this.record.OrgRegNum || '',
          OrgAddress: this.record.OrgAddress || '',
  
          RegCertImage: !(this.record.RegCertImage == null || this.record.RegCertImage == undefined || this.record.RegCertImage == "") ? this.record.RegCertImage.stringValue : this.record.RegCertURL != "" ? this.record.RegCertURL : null,
          IDNoImage: !(this.record.IDNoImage == null || this.record.IDNoImage == undefined || this.record.IDNoImage == '') ? this.record.IDNoImage.stringValue : this.record.IDURL != "" ? this.record.IDURL : null,
          KraPinImage: !(this.record.KraPinImage == null || this.record.KraPinImage == undefined || this.record.KraPinImage == '') ? this.record.KraPinImage.stringValue : this.record.KraURL != "" ? this.record.KraURL : null,
          PassportImage: !(this.record.PassportImage == null || this.record.PassportImage == undefined || this.record.PassportImage == '') ? this.record.PassportImage.stringValue : this.record.PassportURL != "" ? this.record.PassportURL : null,
          CvImage: !(this.record.CvImage == null || this.record.CvImage == undefined || this.record.CvImage == '') ? this.record.CvImage.stringValue : this.record.CvURL != "" ? this.record.CvURL : null,
          BirthCertImage: !(this.record.BirthCertImage == null || this.record.BirthCertImage == undefined || this.record.BirthCertImage == '') ? this.record.BirthCertImage.stringValue : this.record.BirthCertURL != "" ? this.record.BirthCertURL :  null
        });
  
        this.validationID = this.record.IDNo;
        this.validationPIN = this.record.KraPin;
      }

      switch (this.recordType) {
        case 'Settlor':
        case 'Enforcer':
        case 'Testator':
        case 'Executor':
        case 'Witness':
        case 'Guardian':
        case 'Trustee':
          if (
            this.recordType != 'Trustee' || 
            (this.recordType == 'Trustee' && this.record.Category.toLowerCase().includes('individual'))
          ) {
            this.editorForm.get('FirstName')?.addValidators(Validators.required);
            this.editorForm.get('LastName')?.addValidators(Validators.required);
            this.editorForm.get('Phone')?.addValidators(Validators.required);
            this.editorForm.get('Email')?.addValidators(Validators.required);
            this.editorForm.get('IDNo')?.addValidators(Validators.required);
  
            if (this.recordType != 'Guardian' && this.recordType != 'Enforcer')
              this.editorForm.get('PostalAddress')?.addValidators(Validators.required);
            
            if (this.recordType != 'Executor' && this.recordType != 'Witness') {
              this.editorForm.get('KraPin')?.addValidators([Validators.required, Validators.pattern(/^[AP]\d{9}[A-Z]$/)]);
            }
            if (this.recordType == 'Executor' || this.recordType == 'Testator' || this.recordType == 'Settlor' || this.recordType == 'Enforcer') {
              this.editorForm.get('Address')?.addValidators(Validators.required);
            }
          } else {
            this.editorForm.get('InstitutionName')?.addValidators(Validators.required);
            this.editorForm.get('BusinessNature')?.addValidators(Validators.required);
            this.editorForm.get('OrgRegNum')?.addValidators(Validators.required);
            this.editorForm.get('PostalAddress')?.addValidators(Validators.required);
          }

          if (this.recordType == 'Trustee' || this.recordType == 'Guardian') {
            this.editorForm.patchValue({
              Category: this.record.Category || this.TrusteeOptions[0],
            });

            if (this.record.Category.toLowerCase().includes('corporate') ) this.addContactsArray(this.record.ContactPersons);
          }

          break;
        case 'Beneficiary':
          this.editorForm.patchValue({
            Category: this.record.Category || this.BeneficiaryOptions[0],
          });
          
          if (this.record.Category.toLowerCase().includes('individual')) {
            this.editorForm.get('FirstName')?.addValidators(Validators.required);
            this.editorForm.get('LastName')?.addValidators(Validators.required);
            this.editorForm.get('Gender')?.addValidators(Validators.required);
            this.editorForm.get('DOB')?.addValidators(Validators.required);
          } else {
            this.editorForm.get('InstitutionName')?.addValidators(Validators.required);
            this.editorForm.get('BusinessNature')?.addValidators(Validators.required);
            this.editorForm.get('OrgRegNum')?.addValidators(Validators.required);
            this.editorForm.get('OrgAddress')?.addValidators(Validators.required);
            this.editorForm.get('RegCertImage')?.addValidators(Validators.required);
            this.editorForm.get('KraPinImage')?.addValidators(Validators.required);
            
            if (this.record.Category.toLowerCase().includes('institution') ) this.addContactsArray(this.record.ContactPersons);
          }

          break;
        default:
          break;
      }

      if (this.record.Relationship && this.record.Relationship.length > 0) {
        this.editorForm.get('Relationship')?.addValidators(Validators.required);

        this.validationService.addOrRemoveValidationOnValue(
          this.editorForm.get("Relationship")!,
          "Other",
          this.editorForm,
          "Other"
        );
      }

      if (this.recordType == 'Settlor' || this.recordType == 'Witness') {
        this.editorForm.get('IDNoImage')?.addValidators(Validators.required);
        this.editorForm.get('KraPinImage')?.addValidators(Validators.required);
        this.editorForm.get('PassportImage')?.addValidators(Validators.required);
      }
      if (this.recordType == 'Beneficiary') {
        if (this.record.Category.toLowerCase() == 'individual') {
          if (this.getDateDifference(this.record.DOB) == false)
            this.editorForm.get('BirthCertImage')?.addValidators(Validators.required);
          else {
            this.editorForm.get('IDNoImage')?.addValidators(Validators.required);
            this.editorForm.get('KraPinImage')?.addValidators(Validators.required);
          }
        } else {
          this.editorForm.get('RegCertImage')?.addValidators(Validators.required);
          this.editorForm.get('KraPinImage')?.addValidators(Validators.required);
        }
      }
      if (this.recordType == 'Guardian' || this.recordType == 'Testator' || this.recordType == 'Enforcer') {
        this.editorForm.get('IDNoImage')?.addValidators(Validators.required);
        this.editorForm.get('KraPinImage')?.addValidators(Validators.required);
      }
      if (this.recordType == 'Trustee') {
        if (this.record.Category.toLowerCase().includes('individual') ) {
          this.editorForm.get('IDNoImage')?.addValidators(Validators.required);
          this.editorForm.get('KraPinImage')?.addValidators(Validators.required);
          this.editorForm.get('PassportImage')?.addValidators(Validators.required);
          this.editorForm.get('CvImage')?.addValidators(Validators.required);
        } else {
          this.editorForm.get('RegCertImage')?.addValidators(Validators.required);
          this.editorForm.get('KraPinImage')?.addValidators(Validators.required);
        }
      }

    }
  }


  idObject: any = null;
  kraObject: any = null;
  birthCertObject: any = null;
  regCertObject: any = null;
  passportObject: any = null;
  cvObject: any = null;
  
  get planType(): 0 | 1 {
    return this.estateControlService.planType;
  }

  get loading() {
    return this.notificationService.loading;
  }
  get minDate() {
    return this.tabControlService.minDOBDate;
  }
  get maxDate() {
    return this.tabControlService.maxDOBDate;
  }
  
  get loadingTitles(): boolean {
    return this.tabControlService.loadingTitles;
  }
  get TitleOptions(): any {
    return this.tabControlService.TitleOptions;
  }
  get GuardianOptions() {
    return this.tabControlService.GuardianOptions;
  };
  get BeneficiaryOptions():any {
    return this.tabControlService.BeneficiaryOptions;
  }
  get RelationOptions():any {
    return this.tabControlService.RelationOptions;
  }
  get TrusteeOptions():any {
    return this.estateControlService.TrusteeOptions;
  }
  get GenderOptions():any {
    return this.tabControlService.GenderOptions;
  }
  get loadingBizNature(): boolean {
    return this.tabControlService.loadingBizNature;
  }
  get BusinessNatureOptions(): any {
    return this.tabControlService.BusinessNatureOptions;
  }

  updateIDObject(object: any): void {
    this.idObject = object;
  }
  updateKraObject(object: any): void {
    this.kraObject = object;
  }
  updateBirthCertObject(object: any): void {
    this.birthCertObject = object;
  }
  updateRegCertObject(object: any): void {
    this.regCertObject = object;
  }
  updatePassportObject(object: any): void {
    this.passportObject = object;
  }
  updateCVObject(object: any): void {
    this.cvObject = object;
  }

  createContactsGroup(contact: any = null): FormGroup {
    if (contact) {
      return this._formBuilder.group({
        Title: [contact.Title || ''],
        FirstName: [contact.FirstName || '', Validators.required],
        MiddleName: [contact.MiddleName || ''],
        LastName: [contact.LastName || '', Validators.required],
        Phone: [contact.Phone || '', Validators.required],
        Email: [contact.Email || '', [Validators.email, Validators.required]]
      });

    } else {
      return this._formBuilder.group({
        Title: [''],
        FirstName: ['', Validators.required],
        MiddleName: [''],
        LastName: ['', Validators.required],
        Phone: ['', Validators.required],
        Email: ['', [Validators.email, Validators.required]]
      });
    }
    
  };
  getContacts() : FormArray {
    return this.editorForm.get('ContactPersons') as FormArray;  
  }
  getContactsFormGroup(index: number): FormGroup {
    return this.getContacts().at(index) as FormGroup;
  }
  async addNewContact() {
    const fields = this.getContacts();
    fields.push(this.createContactsGroup());
    this.editorForm.setControl('ContactPersons', fields);
  }
  async removeContact(j: number) {
    const fields = this.getContacts();
    fields.removeAt(j);
  }
  addContactsArray = (contactList: any[]) => {
    this.editorForm.addControl('ContactPersons', this._formBuilder.array([
      this.createContactsGroup(contactList[0])
    ]));

    if (contactList.length > 1) {
      const fields = this.getContacts();
      contactList.some((contact: any, index: number) => {
        if (index > 0) fields.push(this.createContactsGroup(contact));
      })
    }
  }

  getDateDifference(date: string | undefined | null) :boolean {
    if (!(date === null || date === undefined)) {
      const currentDate = new Date();
      const dobDate = new Date(date);
      const ageDifferenceInMilliseconds = currentDate.getTime() - dobDate.getTime();
      const ageDate = new Date(ageDifferenceInMilliseconds);

      // Calculate age based on the year.
      const age = Math.abs(ageDate.getUTCFullYear() - 1970);
      if (age < 18) {
        return false;
      } else {
        return true;
      } 
    } else {
      return false;
    }
  }
  removeUrlFromArray(array: UrlObject[], targetUrl: string): UrlObject[] {
    return array.filter(item => item.Url !== targetUrl);
  }

  async populateData(): Promise<void> {
    if (this.TitleOptions.length === 0) {
      this.tabControlService.loadingTitles = true;
      await this.tabControlService.getTitlesList();
      this.tabControlService.loadingTitles = false;
    }

    if (this.recordType === 'Beneficiary' || this.recordType === 'Trustee' ) {
      if (this.BusinessNatureOptions.length === 0) {
        this.tabControlService.loadingBizNature = true;
        await this.tabControlService.getBusinessNatOptionsList();
        this.tabControlService.loadingBizNature = false;
      }
    }
  }

  async updateRecordDetails(): Promise<void> {
    try {
      this.notificationService.isLoading(true);
      let data = {...this.editorForm.value, ...{TrustID: this.estateControlService.TrustID?.toString()}};
      let url = Constants.privateSettlorURL;

      if (this.record.Relationship && this.record.Relationship.length > 0) {
        if (data.Other && data.Other != '') {
          const other = data.Other;
          data.Relationship = other;
        }
        if (data.hasOwnProperty('Other')) {
          delete data['Other'];
        }
      }

      let id;
      let kra;
      let birthCert;
      let regCert;
      let cv;
      let passport;

      if (this.record.IDURL.length > 0) {
        id = null;
      }
      if (this.record.KraURL.length > 0) {
        kra = null;
      }
      if (this.record.BirthCertURL.length > 0) {
        birthCert = null;
      }
      if (this.record.RegCertURL.length > 0) {
        regCert = null;
      }
      if (this.record.CvURL.length > 0) {
        cv = null;
      }
      if (this.record.PassportURL.length > 0) {
        passport = null;
      }

      if (this.idObject) id = this.idObject;
      if (this.kraObject) kra = this.kraObject;
      if (this.birthCertObject) birthCert = this.birthCertObject;
      if (this.regCertObject) regCert = this.regCertObject;
      if (this.cvObject) cv = this.cvObject;
      if (this.passportObject) passport = this.passportObject;

      switch (this.recordType) {
        case 'Settlor':
          data = {
            ...data,
            ...{
              IDNoImage: id,
              KraPinImage: kra,
              PassportImage: passport,
              SettlorID: this.record.SettlorID
            }};
          break;
        case 'Beneficiary':
          if (this.record.Category.toLowerCase().includes('individual')) {
            if (this.getDateDifference(this.record.DOB)) {
              data = {
                ...data,
                ...{
                  IDNoImage: id,
                  KraPinImage: kra,
                  BeneficiaryID: this.record.BeneficiaryID
                }};
            } else {
              data = {
                ...data,
                ...{
                  BirthCertImage: birthCert,
                  BeneficiaryID: this.record.BeneficiaryID
                }};
            }
            
          } else {
            data = {
              ...data,
              ...{
                KraPinImage: kra,
                RegCertImage: regCert,
                BeneficiaryID: this.record.BeneficiaryID
            }};
          }
          if (data.ContactPersons && data.ContactPersons.length > 0) {
            let contacts: any[] = data.ContactPersons;
            contacts.some((contact: any)=> {
              this.record.ContactPersons.some((ogContact: any)=> {
                if (
                  contact.Phone == ogContact.Phone &&
                  contact.Email == ogContact.Email
                ) {
                  contact = {...contact, ...{ContactID: ogContact.ContactID}};
                  if (!environment.production) console.log('Contact ID updated : ', contact);
                }
              })
            })
            data.ContactPersons = contacts;
          }
          url = Constants.estateBeneficiaryURL;
          break;
        case 'Trustee':
          if (this.record.Category.toLowerCase().includes('individual')) {
            data = {
              ...data,
              ...{
                IDNoImage: id,
                KraPinImage: kra,
                PassportImage: passport,
                CvImage: cv,
                TrusteeID: this.record.TrusteeID
              }};
          } else {
            data = {
              ...data,
              ...{
                RegCertImage: regCert,
                KraPinImage: kra,
                TrusteeID: this.record.TrusteeID
            }};
          }
          if (data.ContactPersons && data.ContactPersons.length > 0) {
            let contacts: any[] = data.ContactPersons;
            contacts.some((contact: any)=> {
              this.record.ContactPersons.some((ogContact: any)=> {
                if (
                  contact.Phone == ogContact.Phone &&
                  contact.Email == ogContact.Email
                ) {
                  contact = {...contact, ...{ContactID: ogContact.ContactID}};
                  if (!environment.production) console.log('Contact ID updated : ', contact);
                }
              })
            })
            data.ContactPersons = contacts;
          }
          url = Constants.privateTrusteeURL;
          break;
        case 'Enforcer':
          data = {
            ...data,
            ...{
              IDNoImage: id,
              KraPinImage: kra,
              EnforcerID: this.record.EnforcerID
            }};
          url = Constants.privateEnforcerURL;
          break;
        case 'Guardian':
          data = {
            ...data,
            ...{
              IDNoImage: id,
              KraPinImage: kra,
              AccountType: this.estateControlService.planType == 0 ? 'PrivateTrust' : 'WillCreation',
              BeneficiaryID: this.estateControlService.beneficiaries[this.parentIndex].BeneficiaryID,
              GuardianID: this.record.GuardianID
            }};
          url = Constants.estateGuardianURL;
          break;
        case 'Testator':
          data = {
            ...data,
            ...{
              IDNoImage: id,
              KraPinImage: kra,
              TestatorID: this.record.TestatorID
            }};
          url = Constants.willTestatorURL;
          break;
        case 'Executor':
          data = {
            ...data,
            ...{
              ExecutorID: this.record.ExecutorID
            }};
          url = Constants.willExecutorURL;
          break;
        case 'Witness':
          data = {
            ...data,
            ...{
              IDNoImage: id,
              KraPinImage: kra,
              PassportImage: passport,
              WitnessID: this.record.WitnessID
            }};
          url = Constants.willWitnessURL;
          break;
        default:
          break;
      }

      const response = await this.apiService.postRequest(environment.baseUrl + url, data);

      if (response.Status === 1) {
        let currentRecords;

        if (this.record.Relationship && this.record.Relationship.length > 0) {
          data = {...data, ...{Other: this.editorForm.value.Other}};
          data.Relationship = this.editorForm.value.Relationship;
        }

        if (data.hasOwnProperty('TrustID')) {
          delete data['TrustID'];
        }
              
        switch (this.recordType) {
          case 'Settlor':
            currentRecords = this.estateControlService.settlors;
            Object.assign(
              currentRecords[this.recordIndex],
              {
                ...data,
                ...{
                  verified: true,
                  detailsDone: true,
                  done: true
              }});

            await this.estateControlService.updateRecord(1, currentRecords);
            break;
          case 'Beneficiary':
            currentRecords = this.estateControlService.beneficiaries;
            Object.assign(
              currentRecords[this.recordIndex],
              {
                ...data,
                ...{
                  personalDone: true,
                  contactsDone: true,
                  docsDone: true,
                  done: true,
              }});

            if (this.planType == 0) await this.estateControlService.updateRecord(3, currentRecords);
            else await this.estateControlService.updateRecordWill(5, currentRecords);
            break;
          case 'Trustee':
            currentRecords = this.estateControlService.trustees;
            Object.assign(
              currentRecords[this.recordIndex],
              {
                ...data,
                ...{
                  personalDone: true,
                  contactsDone: true,
                  done: true,
              }});

            this.estateControlService.updateRecord(5, currentRecords);
            break;
          case 'Enforcer':
            this.estateControlService.updateRecord(6, {...data, ...{done: true}});
            break;
          case 'Guardian':
            currentRecords = this.estateControlService.beneficiaries[this.parentIndex];
            let guards: any[] = currentRecords.Guardians;
            
            if (data.hasOwnProperty('AccountType')) {
              delete data['AccountType'];
            }
            if (data.hasOwnProperty('BeneficiaryID')) {
              delete data['BeneficiaryID'];
            }

            Object.assign(
              guards[this.recordIndex],
              {...data, ...{done: true}}
            );

            currentRecords.Guardians = guards;

            if (this.planType == 0) await this.estateControlService.updateRecord(3, currentRecords);
            else await this.estateControlService.updateRecordWill(5, currentRecords);
            break;
          case 'Testator':
            currentRecords = this.estateControlService.testators;
            Object.assign(
              currentRecords[this.recordIndex],
              {
                ...data,
                ...{
                  verified: true,
                  detailsDone: true,
                  done: true
              }});

            await this.estateControlService.updateRecordWill(1, currentRecords);
            break;
          case 'Executor':
            currentRecords = this.estateControlService.executors;
            Object.assign(
              currentRecords[this.recordIndex],
              {
                ...data,
                ...{
                  done: true
              }});

            await this.estateControlService.updateRecordWill(3, currentRecords);
            break;
          case 'Witness':
            currentRecords = this.estateControlService.witnesses;
            Object.assign(
              currentRecords[this.recordIndex],
              {
                ...data,
                ...{
                  verified: true,
                  done: true
              }});

            await this.estateControlService.updateRecordWill(6, currentRecords);
            break;
          default:
            break;
        }

        this.notificationService.viewToast('success', 'Record updated');
        this.closePopup();
      } else {
        this.notificationService.viewToast('error', 'Record update failed');
        console.error(response);
      }
      
      this.notificationService.isLoading(false);
    } catch (error) {
      console.log(error);
    }
  }

  async onConfirmClick(): Promise<void> {
    if (this.recordType === 'Asset') {
      this.closePopup();
    } else {
      await this.updateRecordDetails();
    }
  }

  closePopup(): void {
    this.close.emit();
  }
}
