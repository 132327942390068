import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { OtpPopupComponent } from 'src/app/components/otp-popup/otp-popup.component';
import { ApiService } from 'src/app/services/api/api.service';
import { TabControlService } from 'src/app/services/tab-control.service';
import { ValidationService } from 'src/app/services/validators/validation.service';
import { environment } from 'src/environments/environment';
import * as Constants from "../../../../constants/constants";
import { checkTime } from 'src/app/util/Helper';
import { createIDsValidator } from 'src/app/services/validators/custom.validators';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-step2-settlor',
  templateUrl: './step2-settlor.component.html',
  styleUrls: ['./step2-settlor.component.scss']
})
export class Step2SettlorComponent implements OnInit {

  checkerPath: string = '../../../../assets/fi_check.png';
  title: string = 'Select Settlor Type.';
  subtitle: string = 'A settlor is the person or entity that intends to set up the trust and who prescribes the terms and conditions of the Trust. Please select whether you are registering as an institution or as an individual(s)';
  organizationText: string = 'Organization/Institution';
  individualText: string = 'Individual';

  sentence: string = 'Kindly ensure you have copies of the below documents required for set up:';
  point1Settlor: string = 'Copies of ID, KRA PIN of the individual settlors';
  point1Institution: string = 'Copy of Registration Certificate, KRA PIN for the Organisation';
  point2: string = ' Copies of ID, KRA PIN of the guardian(s)';
  point3: string = 'Birth certificate of the beneficiaries (if minors) OR Copy of ID and KRA (if beneficiary is above 18 years), OR Copy of Registration Certificate and KRA PIN if beneficiary is an organisation';

  formTitle: string = 'Please Enter Settlor Details.';
  formSubtitle: string = 'In the event that there is more than one settlor, please click on the \'Add Settlor\' button to enter details of other settlor(s)';
  
  formTitleAlt: string = 'Institution Settlor Details.';
  formSubtitleAlt: string = 'Please enter the institution\'s details below';
  
  titleLabel: string = 'Title';
  titleHint: string = 'Title';
  fNameLabel: string = 'First Name';
  fNameHint : string = 'First name';
  mNameLabel : string = 'Middle Name';
  mNameHint : string = 'Middle name';
  surnameLabel : string = 'Surname';
  surnameHint : string = 'Last name';
  phoneLabel : string = 'Primary Phone Number';
  emailLabel : string = 'Email Address';
  emailHint : string = 'Email address';
  genderLabel: string = 'Gender';
  genderHint: string = 'Select gender';
  nationalityLabel: string = 'Nationality';
  nationalityHint: string = 'Select nationality';
  countryLabel: string = 'Country of Residence';
  countryHint: string = 'Select country';
  countyLabel: string = 'County';
  countyHint: string = 'Select county';

  idLabel: string = 'ID/Passport Number';
  idHint: string = 'Enter ID or Passport Number';
  kraPinLabel: string = 'KRA PIN';
  kraPinHint: string = 'Enter KRA PIN';
  occupationLabel: string = 'Occupation';
  occupationHint: string = 'Enter Occupation';
  addressLabel: string = 'Physical Address';
  addressHint: string = 'Enter Physical address';
  idFileLabel: string = 'Upload copy of ID/Passport';
  kraFileLabel: string = 'Upload KRA PIN';
  addSettlorText: string = '+  Add Another Settlor';
  removeSettlorText: string = 'Remove Settlor';

  isSettlorOrganization: boolean = true;
  settlorFormVisible: boolean = false;

  requiredFieldString: string = 'Required field';
  invalidDocString: string = 'Please attach a valid document';

  currentRecord: any;
  currentRecordVerified: boolean = false;
  settlorVerified: boolean = false;
  otpVerified: boolean = false;

  loading: boolean = false;
  loadingText: string = 'Requesting OTP';
  buttonDisabled: boolean = false;

  continueWithOneSettler: boolean = false;

  loadingRemove: boolean = false;
  currentIndex: number = 0;
  removeButtonText: string = 'Remove Settlor';

  shouldInsert: boolean = false;

  addButtonText: string = 'Verify to add New Settler';

  personalDone: boolean = false;
  docsDone: boolean = false;
  pushDocs: boolean = false;

  addDesceased: boolean = false;

  addText: string = 'Settlor details saved';
  updateText: string = 'Settlor details updated';

  formErrors: { [key: string]: string } = {
    FirstName: '',
    MiddleName: '',
    LastName: '',
    Phone: '',
    Email: '',
    Gender: '',
    Nationality: '',
    County: '',
    IDNo: '',
    KraPin: '',
    Occupation: '',
    Address: '',
    IDNoImage: '',
    KraPinImage: '',
    InstitutionName: '',
    BusinessNature: '',
  };

  validationMessages: { [key: string]: {} } = {
    FirstName: { required: this.requiredFieldString },
    MiddleName: { required: this.requiredFieldString },
    LastName: { required: this.requiredFieldString },
    Phone: { required: this.requiredFieldString, pattern:'Invalid phone number', exist: 'This number is tied to an existing settlor' },
    Email: { required: this.requiredFieldString, email: "Invalid email address", exist: 'This email is tied to an existing settlor' },
    Gender: { required: this.requiredFieldString },
    Nationality: { required: this.requiredFieldString },
    County: { required: this.requiredFieldString },
    IDNo:  { required: this.requiredFieldString, min:'Invalid ID or Passport number', exist: 'This ID is tied to an existing settlor' },
    KraPin: { required: this.requiredFieldString, pattern:'Invalid KRA PIN', exist: 'This PIN is tied to an existing settlor' },
    Occupation: { required: this.requiredFieldString },
    Address: { required: this.requiredFieldString },
    IDNoImage: { required: this.requiredFieldString, invalid: this.invalidDocString },
    KraPinImage: { required: this.requiredFieldString, invalid: this.invalidDocString },
    InstitutionName: { required: this.requiredFieldString },
    BusinessNature: { required: this.requiredFieldString },
  };


  settlorForm = this._formBuilder.group({
    settlors: this._formBuilder.array([
      this.createSettlorsFormGroup()
    ]),
  });

  iSwear = new FormControl(false);

  constructor(
    private _formBuilder: FormBuilder,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private tabControlService: TabControlService,
    private validationService: ValidationService,
    private apiService: ApiService
  ) {
  }

  ngOnInit(): void {
    this.populateForm();

    if (this.tabControlService.activeTab == 2) {
      this.populateData();
    }

    if (this.tabControlService.settlors && this.tabControlService.settlors.length > 0) {
      this.currentRecord = this.tabControlService.settlors[this.tabControlService.settlors.length-1]
    }

    this.settlorForm.valueChanges.subscribe(() => {
      // console.log('::::::::::::::::::::::::::::: FORM + CONTROLS DEBUG\n', this.settlorForm);
      // if (this.settlorForm.valid && this.tabControlService.settlors.length === 0) {
      //   const records = this.settlorForm.controls['settlors'].value;
      //   this.tabControlService.updateRecord(2, records);
      // }
    });
  }

  idObject: any = null;
  kraObject: any = null;

  deathObject: any = null;

  get settlorType(): 0 | 1 {
    return this.tabControlService.settlorType;
  }
  get settlors(): any {
    return this.tabControlService.settlors;
  }

  get loadingTitles(): boolean {
    return this.tabControlService.loadingTitles;
  }
  get TitleOptions(): any {
    return this.tabControlService.TitleOptions;
  }

  get GenderOptions():any {
    return this.tabControlService.GenderOptions;
  }

  get loadingCountries(): boolean {
    return this.tabControlService.loadingCountries;
  }
  get CountryOptions():any {
    return this.tabControlService.CountryOptions;
  }

  get loadingCounties(): boolean {
    return this.tabControlService.loadingCounties;
  }
  get CountyOptions():any {
    return this.tabControlService.CountyOptions;
  }
  get loadingBizNature(): boolean {
    return this.tabControlService.loadingBizNature;
  }
  get BusinessNatureOptions(): any {
    return this.tabControlService.BusinessNatureOptions;
  }

  get saveProgress(): ()=> void {
    return this.tabControlService.saveProgress.bind(this);
  }

  logErrors() {
    this.formErrors = this.validationService.logValidationErrors(
      this.settlorForm,
      this.formErrors,
      this.validationMessages
    );
  }

  async populateData(): Promise<void> {
    if (this.TitleOptions.length === 0) {
      this.tabControlService.loadingTitles = true;
      await this.tabControlService.getTitlesList();
      this.tabControlService.loadingTitles = false;
    }

    if (this.CountryOptions.length === 0) {
      this.tabControlService.loadingCountries = true;
      await this.tabControlService.getCountryList();
      this.tabControlService.loadingCountries = false;
      this.getSettlorFormGroup(this.getSettlors().length-1).get('Nationality')?.setValue('KENYAN');
      this.getSettlorFormGroup(this.getSettlors().length-1).get('Country')?.setValue('KENYA');
    }

    if (this.CountyOptions.length === 0) {
      this.tabControlService.loadingCounties = true;
      await this.tabControlService.getCountyList();
      this.tabControlService.loadingCounties = false;
    }

    if (this.BusinessNatureOptions.length === 0) {
      this.tabControlService.loadingBizNature = true;
      await this.tabControlService.getBusinessNatOptionsList();
      this.tabControlService.loadingBizNature = false;
    }
  }

  populateForm(reset: boolean = false): void {
    if (this.settlors && this.settlors.length > 0) {
      
      if (this.settlors[0].verified && this.settlors[0].verified == true ||
        this.settlors[0].infoDone && this.settlors[0].infoDone == true) {
        if (!this.settlorFormVisible) this.settlorFormVisible = true;
      
        const settlorsArray = this.settlorForm.get('settlors') as FormArray;

        // Clear existing settlors
        while (settlorsArray.length !== 0) {
          settlorsArray.removeAt(0);
        }

        // Add settlors from storage
        this.settlors.forEach((settlor: any) => {
          settlorsArray.push(this.createSettlorsFormGroup(settlor));
        });

        try {
          if (this.settlorType == 0 && this.settlors[this.settlors.length-1].authDone == false) {
            this.validationService.addOrRemoveOneValidator(
              true,
              this.getAuthorisersFormGroup(this.getSettlors().length-1, 0 ).get('Phone')!,
              Validators.required
            );

            this.validationService.addOrRemoveSpecificValidators(
              true,
              this.getAuthorisersFormGroup(this.getSettlors().length-1, 0 ).get('Email')!,
              [
                Validators.required,
                Validators.email]
            );
          }
          
        } catch (error) {
          console.log('Authorisers not ready');
        }
      } else {
        if (reset) {
          const settlorsArray = this.settlorForm.get('settlors') as FormArray;
          // Clear existing settlors
          while (settlorsArray.length !== 0) {
            settlorsArray.removeAt(0);
          }
          settlorsArray.push(this.createSettlorsFormGroup());
        }
      }
    }
  }

  updateIDObject(object: any): void {
    this.idObject = object;
  }
  updateKraObject(object: any): void {
    this.kraObject = object;
  }
  updateDeathObject(object: any): void {
    this.deathObject = object;
  }

  createSettlorsFormGroup(settlor: any = null): FormGroup {
    let form: FormGroup;

    // console.log('scajhbusdycbudsb ', settlor);

    if (settlor) {
      form = this._formBuilder.group({
        // Individual
        Title: [`${settlor.Title || ''}`],
        FirstName: [`${settlor.FirstName || ''}`, Validators.required],
        MiddleName: [`${settlor.MiddleName || ''}`,],
        LastName: [`${settlor.LastName || ''}`, Validators.required],
        Gender: [`${settlor.Gender || ''}`, Validators.required],
        Nationality: [`${settlor.Nationality || ''}`, Validators.required],
        Country: [`${settlor.Country || ''}`, Validators.required],
        County: [`${settlor.County || ''}`],
        IDNo: [`${settlor.IDNo || ''}`, [Validators.required, Validators.min(4)]],
        KraPin: [`${settlor.KraPin || ''}`, [Validators.required, Validators.pattern(/^[AP]\d{9}[A-Z]$/)]],
        Occupation: [`${settlor.Occupation || ''}`],
        
        IDNoImage: [],
        KraPinImage: [],

        // Shared fields
        Phone: [`${settlor.Phone || ''}`, Validators.required],
        Email: [`${settlor.Email || ''}`, [Validators.required, Validators.email]],
        Address: [`${settlor.Address || ''}`, Validators.required],

        //Institution:
        InstitutionName: [`${settlor.InstitutionName || ''}`, Validators.required],
        BusinessNature: [`${settlor.BusinessNature || ''}`, Validators.required],

        Authorisers: this._formBuilder.array([
          this.createAuthorisersGroup(null, true)
        ]),

        Principal: this._formBuilder.group({
          FirstName: [],
          MiddleName: [''],
          LastName: [''],
          DeathCertImage: [],
        }),
      });

      if (settlor.IDNoImage) {
        if (settlor.IDNoImage.stringValue && settlor.IDNoImage.stringValue.length > 0) {
          form.get('IDNoImage')?.setValue(settlor.IDNoImage.stringValue);
        }
        this.updateIDObject(settlor.IDNoImage);
      }
      if (settlor.KraPinImage) {
        if (settlor.KraPinImage.stringValue && settlor.KraPinImage.stringValue.length > 0) {
          form.get('KraPinImage')?.setValue(settlor.KraPinImage.stringValue);
        }
        this.updateKraObject(settlor.KraPinImage);
      }

      if (settlor.Authorisers && settlor.Authorisers.length > 0 && settlor.Authorisers[0].Email && settlor.Authorisers[0].Email !== '') {
        const authArray = form.get('Authorisers') as FormArray;

        while (authArray.length !== 0) {
          authArray.removeAt(0);
        }

        settlor.Authorisers = settlor.Authorisers.filter((contact: any) => {
          if (contact.verified == true) {
            authArray.push(this.createAuthorisersGroup(contact));
            return true;
          } else {
            // authArray.push(this.createAuthorisersGroup());
            return false;
          }
        });

        let currentSettlors = this.tabControlService.settlors;
        Object.assign(currentSettlors[currentSettlors.length - 1], settlor);
        this.tabControlService.updateRecord(2, currentSettlors);
        // this.settlorVerified = true;
      }

      if (settlor.Principal) {
        if (settlor.Principal.DeathCertImage) {
          if (settlor.Principal.DeathCertImage.stringValue && settlor.Principal.DeathCertImage.stringValue.length > 0) {
            form.get('Principal')?.get('DeathCertImage')?.setValue(settlor.Principal.DeathCertImage.stringValue);
          }
          this.updateDeathObject(settlor.Principal.DeathCertImage);
        }
      }

      if (this.settlorType == 1) {
        if (settlor.done === true) {
          this.personalDone = true;
          this.docsDone = true;
          this.currentRecordVerified = true;
        } else {
          if (settlor.verified == true) {
            this.otpVerified = true;
            this.personalDone = true;
            this.pushDocs = true;
            this.docsDone = false;

            this.validationService.addOrRemoveValidator(true, form.get('IDNoImage')!);
            this.validationService.addOrRemoveValidator(true, form.get('KraPinImage')!);
          }
        }
      } else {
        if (settlor.done === true) {
          this.currentRecordVerified = true;
        } else {
          if (settlor.authDone && !settlor.docsDone) {
            this.pushDocs = true;

            this.validationService.addOrRemoveValidator(true, form.get('IDNoImage')!);
            this.validationService.addOrRemoveValidator(true, form.get('KraPinImage')!);
            form.get('Authorisers')?.clearValidators();
            form.updateValueAndValidity();

          }
        }
      }

      if (this.settlorForm && this.getSettlors().length > 0) {
        this.validationService.addOrRemove3Validators(
          true,
          form.get('Email')!,
          [Validators.required,
            Validators.email,
            createIDsValidator('EMAIL', this.getSettlors(), this.settlors)
          ]
        );
        this.validationService.addOrRemoveSpecificValidators(
          true,
          form.get('Phone')!,
          [Validators.required,
            createIDsValidator('TEL', this.getSettlors(), this.settlors)
          ]
        );
        this.validationService.addOrRemove3Validators(
          true,
          form.get('IDNo')!,
          [Validators.required,
            Validators.min(4),
            createIDsValidator('ID', this.getSettlors(), this.settlors)
          ]
        );
        this.validationService.addOrRemove3Validators(
          true,
          form.get('KraPin')!,
          [Validators.required,
            Validators.pattern(/^[AP]\d{9}[A-Z]$/),
            createIDsValidator('PIN', this.getSettlors(), this.settlors)
          ]
        );
      }
      // console.log('Current record verified: ', this.currentRecordVerified, '\n', settlor);
    } else {
      this.pushDocs = false;
      this.docsDone = false;

      let currentSettlors = this.tabControlService.settlors;
      let currentRecord: any = {verified: false, done: false};

      if (this.tabControlService.settlorType == 0) {
        currentRecord = {infoDone: false, authDone: false, docsDone: false, done: false};
      }
        
      if (this.settlorForm && this.getSettlors().length > 0) {
        this.otpVerified = false;

        const settlorsArray = this.getSettlors();
        console.log('Current settlors  : ', settlorsArray);
  
        // console.log('TO basic data :::\n', this.tabControlService.settlors);

        currentSettlors.push(currentRecord);
        this.tabControlService.updateRecord(2, currentSettlors);
  
        form = this._formBuilder.group({
          Title: [''],
          FirstName: ['', Validators.required],
          MiddleName: [''],
          LastName: ['', Validators.required],
          Gender: ['', Validators.required],
          Nationality: ['KENYAN', Validators.required],
          Country: ['KENYA', Validators.required],
          County: [''],
          IDNo: ['', [Validators.required, Validators.min(4), createIDsValidator('ID', this.getSettlors(), this.settlors) ]],
          KraPin: ['', [Validators.required, Validators.pattern(/^[AP]\d{9}[A-Z]$/), createIDsValidator('PIN', this.getSettlors(), this.settlors) ]],
          Occupation: [''],

          IDNoImage: [],
          KraPinImage: [],
          
          Phone: ['', [Validators.required, createIDsValidator('TEL', this.getSettlors(), this.settlors)]],
          Email: ['', [Validators.required, Validators.email, createIDsValidator('EMAIL', this.getSettlors(), this.settlors) ]],
          Address: ['', Validators.required],
          
          //Institution:
          InstitutionName: ['', Validators.required],
          BusinessNature: ['', Validators.required],

          Authorisers: this._formBuilder.array([
            this.createAuthorisersGroup()
          ]),

          Principal: this._formBuilder.group({
            FirstName: [],
            MiddleName: [''],
            LastName: [''],
            DeathCertImage: [],
          }),
        });
      } else {
        if (
          this.settlors && this.settlors.length > 0 &&
          !(this.settlors[0].verified && this.settlors[0].verified == true ||
            this.settlors[0].infoDone && this.settlors[0].infoDone == true)
        ) {
            this.tabControlService.refreshRecord(2);
        }
        
        currentSettlors = this.tabControlService.settlors; // update current settlors var
        // console.log('From basic data :::\n', this.tabControlService.basicData);
        if (this.settlorType == 1) {
          this.otpVerified = true; // Skip OTP for first settlor
          currentRecord = {verified: false, done: false};
        }

        if (
          this.settlors && (
            (this.settlors.length > 0 &&
            !(this.settlors[0].verified && this.settlors[0].verified == true ||
              this.settlors[0].infoDone && this.settlors[0].infoDone == true)) ||
              this.settlors.length == 0
            )
        ) {
          currentSettlors.push(currentRecord);
          this.tabControlService.updateRecord(2, currentSettlors);
        }

        form = this._formBuilder.group({
          Title: [`${this.tabControlService.basicData.Title || ''}`],
          FirstName: [`${this.tabControlService.basicData.FirstName || ''}`, Validators.required],
          MiddleName: [`${this.tabControlService.basicData.MiddleName || ''}`],
          LastName: [`${this.tabControlService.basicData.LastName || ''}`, Validators.required],
          Gender: ['', Validators.required],
          Nationality: ['KENYAN', Validators.required],
          Country: ['KENYA', Validators.required],
          County: [''],
          IDNo: ['', [Validators.required, Validators.min(4)]],
          KraPin: ['', [Validators.required, Validators.pattern(/^[AP]\d{9}[A-Z]$/)]],
          Occupation: [''],

          IDNoImage: [],
          KraPinImage: [],
          
          Phone: [`${this.tabControlService.basicData.Phone || ''}`, Validators.required],
          Email: [`${this.tabControlService.basicData.Email || ''}`, [Validators.required, Validators.email]],
          Address: ['', Validators.required],

          //Institution:
          InstitutionName: ['', Validators.required],
          BusinessNature: ['', Validators.required],

          Authorisers: this._formBuilder.array([
            this.createAuthorisersGroup()
          ]),

          Principal: this._formBuilder.group({
            FirstName: [],
            MiddleName: [''],
            LastName: [''],
            DeathCertImage: [],
          }),
        });
      }
      
    }

    if (this.tabControlService.settlorType === 0) {  // Institution Settlor
      form.get('FirstName')?.clearValidators();
      form.get('LastName')?.clearValidators();
      form.get('Gender')?.clearValidators();
      form.get('Nationality')?.clearValidators();
      form.get('Country')?.clearValidators();
      form.get('IDNo')?.clearValidators();
      form.get('KraPin')?.clearValidators();
      
      form.get('Title')?.setValue('');
      form.get('FirstName')?.setValue('');
      form.get('MiddleName')?.setValue('');
      form.get('LastName')?.setValue('');
      form.get('Nationality')?.setValue('');
      form.get('Country')?.setValue('');

    } else {
      form.get('InstitutionName')?.clearValidators();
      form.get('BusinessNature')?.clearValidators();
      form.get('Authorisers')?.clearValidators();
      form.get('Principal')?.clearValidators();
    }

    form.updateValueAndValidity();

    return form;
  };

  async addNewSettlor() {
    if (this.tabControlService.settlors[0].SettlorID === undefined
      || this.tabControlService.settlors[0].SettlorID === null) {
      this.shouldInsert = true;
      await this.saveSettlor();
    } else {
      const fields = this.settlorForm.get('settlors') as FormArray;
      fields.push(this.createSettlorsFormGroup());
      this.settlorForm.setControl('settlors', fields);
      this.currentRecordVerified = false;
      if (this.settlorType == 1) {
        this.settlorVerified = false;
      }
    }
  }

  async removeSettlor(i:number) {
    this.currentIndex = i;
    const fields = this.settlorForm.get('settlors') as FormArray;
    let currentSettlors = this.tabControlService.settlors;
    
    if (currentSettlors[i] && currentSettlors[i].SettlorID) {
      this.removeButtonText = 'Removing Settlor';
      this.loadingRemove = true;
      
      try {
        if (this.tabControlService.TrustID !== null) {
          const result = await this.tabControlService.removeRecord(
            'Settlers',
            currentSettlors[i].SettlorID,
            parseInt(this.tabControlService.TrustID));

          if (result === 1) {
            this.notificationService.viewToast('success', 'Settlor removed successfully');
            fields.removeAt(i);
            // Remove settlor at index
            currentSettlors.splice(i, 1);;
            this.tabControlService.updateRecord(2, currentSettlors);
          } else {
            this.notificationService.viewToast('error', 'Error removing record');
          }
        } else {
          console.log('Relevant IDs not found');
        }
      } catch (error) {
        console.log(':: Error !! ', error);
      }
      this.removeButtonText = 'Remove Settlor';
      this.loadingRemove = false;
    } else {
      if (currentSettlors[i] && currentSettlors[i].done === false) {
        fields.removeAt(i);
        // Remove settlor at index
        currentSettlors.splice(i, 1);;
        this.tabControlService.updateRecord(2, currentSettlors);
      } else {
        fields.removeAt(i);
      }
    }

    if (this.tabControlService.settlors[this.tabControlService.settlors.length-1].SettlorID) {
      this.currentRecordVerified = true;
      this.settlorVerified = true;
    }
  }

  getSettlors() : FormArray {  
    return this.settlorForm.get("settlors") as FormArray  
  }
  getSettlorFormGroup(index: number): FormGroup {
    const settlors = this.settlorForm.get('settlors') as FormArray;
    return settlors.at(index) as FormGroup;
  }

  createAuthorisersGroup(contact: any = null, isResuming: boolean = false): FormGroup {
    let currentSettlors = this.tabControlService.settlors;
    let currentRecord = currentSettlors[currentSettlors.length-1];
    let currentAuthorisers = currentRecord.Authorisers;
      
    let authForm: FormGroup;

    if (this.settlorType == 1) {
      return this._formBuilder.group({
        Title: [''],
        FirstName: [''],
        MiddleName: [''],
        LastName: [''],
        Phone: [''],
        Email: ['']
      });
    }

    if (contact && contact.Phone !== '' && contact.Email !== '') {
      
      // console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!\n', contact);
      authForm = this._formBuilder.group({
        Title: [`${contact.Title || ''}`],
        FirstName: [`${contact.FirstName || ''}`, Validators.required],
        MiddleName: [`${contact.MiddleName || ''}`],
        LastName: [`${contact.LastName || ''}`, Validators.required],
        Phone: [`${contact.Phone || ''}`, Validators.required],
        Email: [`${contact.Email || ''}`, [Validators.required, Validators.email]]
      });

      if (contact.verified == true) {
        this.settlorVerified = true;
      } else {
        this.settlorVerified = false;
      }

    } else {
      
      if (isResuming) {
        authForm = this._formBuilder.group({
          Title: [''],
          FirstName: ['', Validators.required],
          MiddleName: [''],
          LastName: ['', Validators.required],
          Phone: ['', Validators.required],
          Email: ['', [Validators.required, Validators.email]]
        });

        if (currentAuthorisers[currentAuthorisers.length-1].verified == true) {
          this.settlorVerified = true;
        } else {
          this.settlorVerified = false;
        }

      } else {
        authForm = this._formBuilder.group({
          Title: [''],
          FirstName: [''],
          MiddleName: [''],
          LastName: [''],
          Phone: [''],
          Email: ['']
        });

        const newAuthoriser = {verified: false};
        if (currentAuthorisers) {
          if (currentAuthorisers[currentAuthorisers.length-1].verified == true) {
            currentAuthorisers.push(newAuthoriser);
            currentRecord = {...currentRecord, ...{Authorisers: currentAuthorisers}};
            Object.assign(currentSettlors[currentSettlors.length - 1], currentRecord);
          }
          
        } else {
          let newAuthorisers: any[] = [];
          newAuthorisers.push(newAuthoriser);
          
          currentRecord = {...currentRecord, ...{Authorisers: newAuthorisers}};
          Object.assign(currentSettlors[currentSettlors.length - 1], currentRecord);
        }

        this.tabControlService.updateRecord(2, currentSettlors);
        this.settlorVerified = false;
      }
    }

    try {
      if (this.getAuthorisers(this.getSettlors().length-1)
        && this.getAuthorisers(this.getSettlors().length-1).length > 0
        && currentAuthorisers && currentAuthorisers.length > 0) {
        
        this.validationService.addOrRemoveValidator(true, authForm.get('FirstName')!);
        this.validationService.addOrRemoveValidator(true, authForm.get('LastName')!);

        this.validationService.addOrRemoveSpecificValidators(
          true,
          authForm.get('Phone')!,
          [
            Validators.required,
            createIDsValidator(
            'TEL',
            this.getAuthorisers(
              this.getAuthorisers(this.getSettlors().length-1).length-1 ),
              currentAuthorisers
          )]
        );

        this.validationService.addOrRemove3Validators(
          true,
          authForm.get('Email')!,
          [
            Validators.required,
            Validators.email,
            createIDsValidator(
            'EMAIL',
            this.getAuthorisers(
              this.getAuthorisers(this.getSettlors().length-1).length-1 ),
              currentAuthorisers
          )]
        );
      }

    } catch (error) {
      console.log('Authorisers not ready ');
    }
    
    return authForm;
  };

  async addNewAuthoriser(index:number) {
    const fields: FormArray = this.getAuthorisers(index);
    fields.push(this.createAuthorisersGroup());

    this.getSettlorFormGroup(index).setControl('Authorisers', fields);
    this.settlorVerified = false;
  }
  async removeAuthoriser(i:number, j: number) {
    const fields = this.getAuthorisers(i);
    fields.removeAt(j);
    this.settlorVerified = true;
  }
  getAuthorisers(index: number) : FormArray {
    return this.getSettlors().at(index).get('Authorisers') as FormArray;  
  }
  getAuthorisersFormGroup(index: number, innerdex: number): FormGroup {
    return this.getAuthorisers(index).at(innerdex) as FormGroup;
  }

  getPrincipalFormGroup(index: number): FormGroup {
    const settlorsArray = this.getSettlorFormGroup(index);
    return settlorsArray.get('Principal') as FormGroup;
  }

  showSettlorForm(): void {
    if (!this.settlorFormVisible) {
      this.settlorFormVisible = true;
      this.populateForm(true);
    }
    this.settlorForm.updateValueAndValidity();
  }

  onOptionChange(value: boolean): void {
    this.isSettlorOrganization = value;

    if (this.isSettlorOrganization) {
      this.tabControlService.settlorType = 0;
    } else {
      this.tabControlService.settlorType = 1;
    }
    console.log('Selected option:', value, ', molo ', this.tabControlService.settlorType);
  }

  skipAddPrincipal(): void {
    let currentSettlors = this.tabControlService.settlors;
    let currentRecord = currentSettlors[currentSettlors.length-1];
    currentRecord.done = true;

    Object.assign(currentSettlors[currentSettlors.length - 1], currentRecord);
    this.tabControlService.updateRecord(2, currentSettlors);
    this.currentRecordVerified = true;
  }

  ondesceasedChange(value: boolean): void {
    this.addDesceased = value;

    if (value == true) {
      this.getSettlorFormGroup(0).get('Principal')?.get('FirstName')?.addValidators([Validators.required]);
      this.getSettlorFormGroup(0).get('Principal')?.get('LastName')?.addValidators([Validators.required]);
      this.getSettlorFormGroup(0).get('Principal')?.get('DeathCertImage')?.addValidators([Validators.required]);
    } else {
      // Clear form control values
      this.getSettlorFormGroup(0).get('Principal')?.get('FirstName')?.setValue('');
      this.getSettlorFormGroup(0).get('Principal')?.get('MiddleName')?.setValue('');
      this.getSettlorFormGroup(0).get('Principal')?.get('LastName')?.setValue('');
      this.getSettlorFormGroup(0).get('Principal')?.get('DeathCertImage')?.setValue('');

      this.getSettlorFormGroup(0).get('Principal')?.get('FirstName')?.setErrors(null);
      this.getSettlorFormGroup(0).get('Principal')?.get('LastName')?.setErrors(null);
      this.getSettlorFormGroup(0).get('Principal')?.get('DeathCertImage')?.setErrors(null);

      this.getSettlorFormGroup(0).get('Principal')?.get('FirstName')?.setValidators(null);
      this.getSettlorFormGroup(0).get('Principal')?.get('LastName')?.setValidators(null);
      this.getSettlorFormGroup(0).get('Principal')?.get('DeathCertImage')?.setValidators(null);

      // Clear validators
      this.getSettlorFormGroup(0).get('Principal')?.get('FirstName')?.clearValidators();
      this.getSettlorFormGroup(0).get('Principal')?.get('LastName')?.clearValidators();
      this.getSettlorFormGroup(0).get('Principal')?.get('DeathCertImage')?.clearValidators();
    }

    // Reset validation status
    this.getSettlorFormGroup(0).get('Principal')?.updateValueAndValidity();
    this.settlorForm.updateValueAndValidity();
    // console.log('Selected option:', value);
  }

  async openOtpModal() {
    this.loadingText = 'Requesting OTP';
    this.loading = true;
    this.notificationService.isLoading(true);

    try {
      const records = this.settlorForm.controls['settlors'].value;
      let data = {
        FirstName: records[records.length-1].FirstName,
        LastName: records[records.length-1].LastName,
        Phone: records[records.length-1].Phone,
        Email: records[records.length-1].Email
      };

      if (this.tabControlService.settlorType == 0) {
        const auths = records[records.length-1].Authorisers;
        data = {
          FirstName: auths[auths.length-1].FirstName,
          LastName: auths[auths.length-1].LastName,
          Phone: auths[auths.length-1].Phone,
          Email: auths[auths.length-1].Email
        };

        this.tabControlService.OTPMessage = 'In order to proceed, please enter the One Time Password (OTP) sent to the Authoriser\'s email and phone';
      } else {
        this.tabControlService.OTPMessage = 'In order to proceed, please enter the One Time Password (OTP) sent to the Settlor\'s email and phone';
      }

      // console.log('Dat ', data);

      let savedTime = this.tabControlService.OTPRequestStats;
      let response = {Status: 0, Message: 'Initialized'};

      this.tabControlService.OTPValid = checkTime(savedTime.time);

      if (this.tabControlService.OTPValid && savedTime.phone === data.Phone) {
        response.Status = 1;
        response.Message = 'Current OTP is still Valid';

      } else {
        response = await this.apiService.postRequest(
          environment.baseUrl + Constants.otpRequestURL, data);
      }

      if (response.Status === 1) {
        let currentSettlors = this.tabControlService.settlors;
        let currentRecord = currentSettlors[currentSettlors.length-1];

        currentRecord = {...currentRecord, ...records[records.length-1]};
        
        if (this.settlorType == 0) {
          let currentAuthorisers = currentRecord.Authorisers;
          let currentAuthoriser = currentAuthorisers[currentAuthorisers.length-1];
          currentAuthoriser = {
            ...currentAuthoriser,
            ...records[records.length-1].Authorisers[records[records.length-1].Authorisers.length-1]
          };

          Object.assign(currentAuthorisers[currentAuthorisers.length - 1], currentAuthoriser);
          currentRecord.Authorisers = currentAuthorisers;
          
        }
        
        Object.assign(currentSettlors[currentSettlors.length - 1], currentRecord);
        this.tabControlService.updateRecord(2, currentSettlors);
        
        if (!this.tabControlService.OTPValid) {
          const timeStats = {time: new Date().getTime() / 1000, phone: data.Phone};
          this.tabControlService.OTPRequestStats = timeStats;
          this.tabControlService.OTPValid = true;

          this.notificationService.viewToast(
            'success',
            `OTP sent to ${data.Phone}.`,
            'We have also sent it to the provided Email');

        } else {
          this.notificationService.viewToast(
            'info',
            `Please enter the OTP that was sent to your Phone / Email` );
        }

        this.tabControlService.OTPRequestPage = 'Settlor';
        this.tabControlService.OTPIndex = this.tabControlService.settlors.length-1;

        if (this.settlorType == 0) {
          this.tabControlService.OTPIndex =
            this.tabControlService.settlors[this.tabControlService.settlors.length-1].Authorisers.length-1;
        }
        
        this.loadingText = 'Verify Details';

        const dialogRef = this.dialog.open(OtpPopupComponent, {
          disableClose: true,
        });
    
        dialogRef.componentInstance.otpVerified.subscribe((value: boolean) => {
          this.otpVerified = value;
          // console.log('OTP verification status received from the modal:', this.otpVerified);
        });
    
        dialogRef.afterClosed().subscribe(result => {
          console.group('OTP Dialog ', this.otpVerified);
          if (this.otpVerified == true) {
            this.settlorVerified = true;

            if (this.settlorType == 0) {
              let currentSettlors = this.tabControlService.settlors;
              let currentRecord = currentSettlors[currentSettlors.length-1];

              let currentAuthorisers = currentRecord.Authorisers;
              for (let i=0; i< currentAuthorisers.length; i++) {
                let currentAuthoriser = currentAuthorisers[i];
                currentAuthoriser.verified = true;
                Object.assign(currentAuthorisers[i], currentAuthoriser);
              }
              
              currentRecord.Authorisers = currentAuthorisers;
              console.log('soko ', currentRecord);

              Object.assign(currentSettlors[currentSettlors.length - 1], currentRecord);
              this.tabControlService.updateRecord(2, currentSettlors);
            }

          } else {
            this.settlorVerified = false;
            this.notificationService.viewToast('warning', 'Please verify your details to proceed');
          }
          console.groupEnd();
        });
      } else {
        this.notificationService.viewToast('error', response.Message);
      }
    } catch (error) {
      console.log(':: Error !! ', error);
      this.notificationService.viewToast('error', 'OTP request failed');

    }
    this.notificationService.isLoading(false);
    this.loading = false;
  }

  async saveSettlor(): Promise<void> {
    if (this.pushDocs ||
        (this.tabControlService.settlors[this.tabControlService.settlors.length-1].infoDone &&
          this.tabControlService.settlors[this.tabControlService.settlors.length-1].infoDone == true
        )
      ) this.loadingText = 'Updating Settlor';
    else this.loadingText = 'Saving Settlor';

    let tsMessage = this.addText;

    this.loading = true;
    this.notificationService.isLoading(true);

    try {
      const settlors = this.settlorForm.get('settlors') as FormArray;
      const record: FormGroup = this.getSettlorFormGroup(settlors.length - 1) as FormGroup;

      let category;
      if (this.tabControlService.TrustID === null || settlors.length - 1 === 0) {
        category = 'Primary Settler';
      } else {
        category = 'Secondary Settler';
      }
      
      let data = {
        ...record.value,
        ...{
          TrustID: this.tabControlService.TrustID,
          Category: category,
          SettlorType: this.settlorType == 0 ? 'Institution' : 'Individual'
        }
      };

      let currentSettlors = this.tabControlService.settlors;
      
      if (this.pushDocs) {
        data.IDNoImage = this.idObject;
        data.KraPinImage = this.kraObject;
      } else {
        data.IDNoImage = {name: '', stringValue: ''};
        data.KraPinImage = {name: '', stringValue: ''};

        if (this.tabControlService.settlorType === 0) {
          if (currentSettlors[currentSettlors.length - 1].infoDone === true) {
            if (currentSettlors[currentSettlors.length - 1].authDone === true) {
              data.Authorisers = [];

              if (currentSettlors[currentSettlors.length - 1].docsDone === true) {
                
                data.Principal.DeathCertImage = this.deathObject;
              }  
            }
          } else {
            data.Authorisers = [];
          }
        } else {
          data.Authorisers = [];
        }
      }

      if (currentSettlors[currentSettlors.length - 1].SettlorID) {
        data = {...data, ...{SettlorID: currentSettlors[currentSettlors.length - 1].SettlorID}};
      }

      // console.log('Trust ID >> \n', data.TrustID);
      // console.log('Settlor ID >> \n', data.SettlorID);
      
      const response = await this.apiService.postRequest(
        environment.baseUrl + Constants.saveSettlorURL, data, false);
      
      // console.log('::::: RESPONSE ::::::\n', response);
      if (response.Status === 1) {
        // const previousData = this.tabControlService.settlors;
        // this.tabControlService.updateRecord(2, {...previousData, ...[data]});
        if (this.tabControlService.TrustID === null) {this.tabControlService.TrustID = response.TrustID;}

        let currentSettlors = this.tabControlService.settlors;
        const latestRecord = currentSettlors[currentSettlors.length - 1];
        let currentAuthorisers = latestRecord.Authorisers;
        let updatedRecord = {...latestRecord, ...record.value, ...{SettlorID: response.SettlorID, Category: category}};

        if (this.settlorType == 0) {
          updatedRecord.Authorisers = currentAuthorisers;
        }
        Object.assign(currentSettlors[currentSettlors.length - 1], updatedRecord);

        await this.tabControlService.updateRecord(2, currentSettlors);
        console.log('>> New Settlors !! \n', this.tabControlService.settlors);

        if (this.pushDocs) {
          tsMessage = this.updateText;
          let currentSettlors = this.tabControlService.settlors;
          let currentRecord = currentSettlors[currentSettlors.length-1];
          
          currentRecord.IDNoImage = this.idObject;
          currentRecord.KraPinImage = this.kraObject;

          if (response.IdURL) {
            this.tabControlService.updateDocSummary(response.IdURL);
            currentRecord = {...currentRecord, ...{IDURL: response.IdURL}};
          }
          if (response.KraURL) {
            this.tabControlService.updateDocSummary(response.KraURL);
            currentRecord = {...currentRecord, ...{KraURL: response.KraURL}};
          }
          
          if (this.tabControlService.settlorType == 0) { // Settlor is institution
            currentRecord.docsDone = true;

          } else {
            currentRecord.done = true;
            this.currentRecordVerified = true;
          }
          
          this.docsDone = true;
          this.pushDocs = false;

          Object.assign(currentSettlors[currentSettlors.length - 1], currentRecord);
          await this.tabControlService.updateRecord(2, currentSettlors);

          // if (this.continueWithOneSettler) {
          //   this.navigateFunction();
          // }
  
          // if (this.shouldInsert) {
          //   const fields = this.settlorForm.get('settlors') as FormArray;
          //   this.otpVerified = false;
          //   fields.push(this.createSettlorsFormGroup());
          //   this.settlorForm.setControl('settlors', fields);
          //   this.currentRecordVerified = false;
  
          //   this.shouldInsert = false;
          // }

        } else {
          // Update settlor progress
          let currentSettlors = this.tabControlService.settlors;
          let currentRecord = currentSettlors[currentSettlors.length-1];

          if (this.tabControlService.settlorType == 0) { // Settlor is institution
            if (!currentRecord.infoDone) {
              currentRecord.infoDone = true;

              try {
                this.validationService.addOrRemoveValidator(
                  true,
                  this.getAuthorisersFormGroup(this.getSettlors().length-1, 0 ).get('FirstName')!
                );
                this.validationService.addOrRemoveValidator(
                  true,
                  this.getAuthorisersFormGroup(this.getSettlors().length-1, 0 ).get('LastName')!
                );
                
                this.validationService.addOrRemoveOneValidator(
                  true,
                  this.getAuthorisersFormGroup(this.getSettlors().length-1, 0 ).get('Phone')!,
                  Validators.required
                );
      
                this.validationService.addOrRemoveSpecificValidators(
                  true,
                  this.getAuthorisersFormGroup(this.getSettlors().length-1, 0 ).get('Email')!,
                  [
                    Validators.required,
                    Validators.email]
                );
              } catch (error) {
                console.log('Authorisers not ready');
              }

            } else {
              tsMessage = this.updateText;

              if (!currentRecord.authDone) {
                currentRecord.authDone = true;
                this.pushDocs = true;

                this.validationService.addOrRemoveValidator(true, this.getSettlorFormGroup(settlors.length - 1).get('IDNoImage')!);
                this.validationService.addOrRemoveValidator(true, this.getSettlorFormGroup(settlors.length - 1).get('KraPinImage')!);

              } else {
                if (!currentRecord.done) {
                  if (response.DeathCertURL) {
                    this.tabControlService.updateDocSummary(response.DeathCertURL);

                    let principal = currentRecord.Principal;
                    principal = {...principal, ...{DeathCertURL: response.DeathCertURL}};
                    currentRecord.Principal = principal;
                  }

                  currentRecord.done = true;
                }
                this.currentRecordVerified = true;
              }
            }  

          } else {
            currentRecord.verified = true;
            this.personalDone = true;
            this.pushDocs = true;

            this.validationService.addOrRemoveValidator(true, this.getSettlorFormGroup(settlors.length - 1).get('IDNoImage')!);
            this.validationService.addOrRemoveValidator(true, this.getSettlorFormGroup(settlors.length - 1).get('KraPinImage')!);
          }
          
          Object.assign(currentSettlors[currentSettlors.length - 1], currentRecord);
          await this.tabControlService.updateRecord(2, currentSettlors);
        }

        this.notificationService.viewToast('success', tsMessage);

      } else {
        this.notificationService.viewToast('error', response.Message);
      }
    } catch (error) {
      console.log(':: Error !! ', error);
      this.notificationService.viewToast('error', 'An unexpected error occurred');
    }
    this.notificationService.isLoading(false);
    this.loading = false;
  }

  async updateProgress(): Promise<void> {
    if (this.settlors[this.settlors.length-1].done == false ) {
      await this.saveSettlor();
    }
    this.saveProgress();
  }

  async submitForm(): Promise<void> {
    if (this.currentRecordVerified) {
      this.navigateFunction();
    } else {
      if (this.tabControlService.settlorType == 0) {
        if (this.settlors[this.settlors.length-1].infoDone && !this.settlors[this.settlors.length-1].authDone) {
          if (this.settlorVerified) {
            await this.saveSettlor();
          } else await this.openOtpModal();

        } else {
          await this.saveSettlor();
        }

        if (this.pushDocs) {
          this.getAuthorisers(0).setErrors(null);
          this.getAuthorisers(0).clearValidators();

          for (let i=0; i< this.getAuthorisers(0).length; i++) {
            this.getAuthorisersFormGroup(0, i).get('Phone')?.setErrors(null);
            this.getAuthorisersFormGroup(0, i).get('Phone')?.setValidators(null);

            this.getAuthorisersFormGroup(0, i).get('Email')?.setErrors(null);
            this.getAuthorisersFormGroup(0, i).get('Email')?.setValidators(null);
          }
          this.settlorForm.updateValueAndValidity();
        }

      } else {
        if (this.getSettlors().length === 1) {
          this.saveSettlor();
        } else {
          if (this.settlorVerified) this.saveSettlor();
          else this.openOtpModal();
        }
      }
    }
  }

  async navigateFunction(): Promise<void> {
    console.log('Navigating...');
    await this.tabControlService.changeTab(3);
    this.tabControlService.page2Done = true;
  }
}
